import React from 'react'

const EvolutionTimeLine = ({value, width}) => {

  let bgTimeLine = 'bg-color-plt-2'

  if (value < 20) {
    bgTimeLine = 'bg-color-plt-6'
  } else if (value < 70) {
    bgTimeLine = 'bg-color-plt-4'
  }else {
    bgTimeLine = 'bg-color-plt-2'
  }

  return (
    <div className='flex flex-col items-center justify-center'>
      <div style={{width: width ? width : '100px'}} className='w-[100px] bg-gray-400  rounded-sm flex'>
        <span style={{width: `${value}%`}} className={`w-[${value}%] ${bgTimeLine} h-[100%] text-light px-1 flex`}>{value}%</span>
      </div>
    </div>
  )
}

export default EvolutionTimeLine