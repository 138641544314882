import React from 'react'
import { Link } from 'react-router-dom'

const LinkBtn = ({path, description, icon, className}) => {
  return (
    <Link to={path} className={`${className} flex items-center justify-center mx-1 px-2 py-2 rounded`}>
        <span className='text-md'>{icon}</span>
        <span className='ml-2 text-sm'>{description}</span>
    </Link>
  )
}

export default LinkBtn