import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const OptionClick = ({icon, description, path='#', notifications}) => {

  const [pathSite, setPath] = useState('');

    let win_path = window.location.pathname;

    useEffect(() => {
        // Récupérez seulement le chemin (URL sans le domaine)
        const chemin = win_path;
        setPath(chemin);
    }, [win_path]);


  return (
    <Link to={path} 
        className={`xl:w-[130px] relative xl:h-[120px] w-[46.5%] mb-2 h-[100px] flex flex-col px-2 justify-center text-light items-center rounded-md mr-2 focus:outline-none ${win_path.includes(path) ? 'bg-color-plt-2' : 'bg-color-plt-1'} hover:bg-color-plt-2`}>
        <span className='text-3xl mb-2'>{icon}</span>
        <span className='text-center xl:text-md text-sm'>{description}</span>
        {notifications > 0 && <span className='bg-color-plt-6 absolute -top-[10px] -right-[10px] rounded-full w-[30px] h-[30px] flex justify-center items-center text-sm'>{notifications}</span>}
    </Link>
  )
}

export default OptionClick