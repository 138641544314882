import React from 'react'
import { Button, Input, MainContent, Select } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { getElementData } from '../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const SousActiviteForm = () => {

    const navigate = useNavigate();
    const {id_sous_activite, id_unite_gestion} = useParams();
    const {
        composantes, 
        sousComposantes,
        voletsActivites,
        user, 
        update_sous_activites, 
        create_sous_activites, 
        delete_sous_activites,
        sousActivites,
        categorieDepenses,
        activites
    } = usePlateformContext();

    let check_activite = id_sous_activite === 'nouvelle';
    
    let activite = getElementData(id_sous_activite, sousActivites);
    let activites_data = activites.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let composantes_data = composantes.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let sousComposantes_data = sousComposantes.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let volet_activite_data = voletsActivites.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let categorie_depenses_data = categorieDepenses.filter(item => item.unite_gestion.toString() === id_unite_gestion)


    let model_data = {
        composante: "",
        sous_composante: "",
        volet_activite: "",
        reference: "",
        designation: "",
        date_debut: "",
        date_fin: "",
        montant: "",
        categorie_depense: "",
        nature: "",
        activite: "",
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value});
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.reference) {
                errors.reference = "Entrer la référence";
            }

            if (!credentials.designation) {
                errors.designation = "Entrer la designation";
            }

            if (!credentials.date_debut) {
                errors.date_debut = "Entrer la date de debut";
            }

            if (!credentials.date_fin) {
                errors.date_fin = "Entrer la date de fin";
            }

            if (!credentials.montant) {
                errors.montant = "Entrer le montant";
            }

            if (!credentials.categorie_depense) {
                errors.categorie_depense = "Selectionner la categorie de depense";
            }

            if (!credentials.nature) {
                errors.nature = "Selectionner la nature";
            }

            if (!credentials.activite) {
                errors.activite = "Selectionner l'activité";
            }

            if (!credentials.sous_composante) {
                errors.sous_composante = "Selectionner une sous composante";
            }
           

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_activite ? await update_sous_activites(credentials) : await create_sous_activites(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_activite ? model_data : activite);
    }, [sousComposantes])

    // Options composantes
    const [optionsComposantes, setOptionsComposantes] = useState([]);
    const [optionsSousComposantes, setOptionsSousComposantes] = useState([]);
    const [optionsVoletActivites, setOptionsVoletActivites] = useState([]);
    const [optionsCategorieDepenses, setOptionsCategorieDepenses] = useState([]);
    const [optionsActivites, setOptionsActivites] = useState([]);

    const optionsNature = [
        {value: 'Investissement', label: 'Investissement'},
        {value: 'Fonctionnement', label: 'Fonctionnement'}
    ]


    useEffect(() => {
        let liste_composantes = [];

        composantes_data.map(item => {
            return liste_composantes.push({value : item.id, label: `${item.reference}: ${item.designation}`})
        })

        setOptionsComposantes(liste_composantes);
    }, [composantes])


    useEffect(() => {

        // let sous_comp = getElementData(credentials.sous_composante, sousComposantes)

        // if (sous_comp && sous_comp?.composante?.toString() !== credentials.composante) {
        //     setCredentials({...credentials, ["sous_composante"] : ""});
        // }

        let liste_sous_composante = [];

        sousComposantes_data.filter(item => item.composante.toString() === credentials?.composante.toString()).map(item => {
            return liste_sous_composante.push({value : item.id, label: `${item.reference}: ${item.designation}`})
        })

        setOptionsSousComposantes(liste_sous_composante);
    }, [credentials])

    useEffect(() => {

        // let sous_comp = getElementData(credentials.sous_composante, sousComposantes)

        // if (sous_comp && sous_comp?.composante?.toString() !== credentials.composante) {
        //     setCredentials({...credentials, ["sous_composante"] : ""});
        // }

        let liste_volet_activite = [];

        volet_activite_data.filter(item => item.sous_composante.toString() === credentials?.sous_composante.toString()).map(item => {
            return liste_volet_activite.push({value : item.id, label: `${item.designation}`})
        })

        setOptionsVoletActivites(liste_volet_activite);
    }, [credentials])

    useEffect(() => {

        // let sous_comp = getElementData(credentials.sous_composante, sousComposantes)

        // if (sous_comp && sous_comp?.composante?.toString() !== credentials.composante) {
        //     setCredentials({...credentials, ["sous_composante"] : ""});
        // }

        let liste_activtes = [];

        activites_data.filter(item => item.volet_activite.toString() === credentials?.volet_activite.toString()).map(item => {
            return liste_activtes.push({value : item.id, label: `${item.designation}`})
        })

        setOptionsActivites(liste_activtes);
    }, [credentials])

    useEffect(() => {
        let options = []

        categorie_depenses_data.map(item => {
            options.push({value: item.id, label: `${item.designation}`})
        })

        setOptionsCategorieDepenses(options)

    }, [categorieDepenses])

    // console.log(optionsSousComposantes)


  return (
    <MainContent title={`${check_activite ? "Créer une activité" : activite?.designation}`}>
        <div className='flex items-center mt-5 justify-between px-2 md:px-1 py-4'>

            <div className='xl:max-w-[70%] max-w-[50%]'>
                <h1 className='font-bold text-lg'>{check_activite ? "Nouvelle activité" : "Modifier l'activité"}</h1>
                <p className='text-md font-light'>{check_activite ? "Enregistrer une nouvelle activité pour le projet" : `Modifier l'activité "${activite?.designation}"`}</p>
            </div>

            <div className='flex items-center'></div>
        </div>

        <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>
                <Input
                    type={"text"}
                    label={"Référence"}
                    required={true}
                    placeholder={"Ex: A.1.1"}
                    autoComplete='off'
                    value={credentials?.reference}
                    error={credentialsError.reference}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("reference", null)}
                    name={"reference"}
                />

                <Input
                    type={"text"}
                    label={"Designation"}
                    required={true}
                    placeholder={"Designation"}
                    autoComplete='off'
                    value={credentials?.designation}
                    error={credentialsError.designation}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("designation", null)}
                    name={"designation"}
                />

                <Select
                    label={"Composante"}
                    required={true}
                    placeholder={"Selectionner la composante"}
                    autoComplete='off'
                    value={credentials?.composante}
                    error={credentialsError.composante}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("composante", null)}
                    name={"composante"}
                    options={optionsComposantes}
                />

                <Select
                    label={"Sous-composante"}
                    required={true}
                    placeholder={"Selectionner la sous-composante"}
                    autoComplete='off'
                    value={credentials?.sous_composante}
                    error={credentialsError.sous_composante}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("sous_composante", null)}
                    name={"sous_composante"}
                    options={optionsSousComposantes}
                />

                <Select
                    label={"Volet d'activité"}
                    required={false}
                    placeholder={"Selectionner le volet d'activité"}
                    autoComplete='off'
                    value={credentials?.volet_activite}
                    error={credentialsError.volet_activite}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("volet_activite", null)}
                    name={"volet_activite"}
                    options={optionsVoletActivites}
                />

                <Select
                    label={"Activité"}
                    required={false}
                    placeholder={"Selectionner l'activité"}
                    autoComplete='off'
                    value={credentials?.activite}
                    error={credentialsError.activite}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("activite", null)}
                    name={"activite"}
                    options={optionsActivites}
                />

                <Select
                    label={"Categorie de depense"}
                    required={false}
                    placeholder={"Selectionner le categorie de depense"}
                    autoComplete='off'
                    value={credentials?.categorie_depense}
                    error={credentialsError.categorie_depense}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("categorie_depense", null)}
                    name={"categorie_depense"}
                    options={optionsCategorieDepenses}
                />

                <Select
                    label={"Nature"}
                    required={false}
                    placeholder={"Selectionner le nature"}
                    autoComplete='off'
                    value={credentials?.nature}
                    error={credentialsError.nature}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("nature", null)}
                    name={"nature"}
                    options={optionsNature}
                />

                <Input
                    type={"text"}
                    label={"Montant (FCFA)"}
                    required={true}
                    placeholder={"Montant (FCFA)"}
                    autoComplete='off'
                    value={credentials?.montant}
                    error={credentialsError.montant}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("montant", null)}
                    name={"montant"}
                />

                <span className='flex mt-2 text-sm font-normal'>Periode d'execution</span>

                <div className='flex xl:flex-row flex-col'>
                    <Input
                        type={"date"}
                        label={"Date de debut"}
                        required={true}
                        placeholder={"Date de debut"}
                        autoComplete='off'
                        value={credentials?.date_debut}
                        error={credentialsError.date_debut}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("date_debut", null)}
                        name={"date_debut"}
                        className={"w-full xl:mr-1"}
                    />

                    <Input
                        type={"date"}
                        label={"Date de fin"}
                        required={true}
                        placeholder={"Date de fin"}
                        autoComplete='off'
                        value={credentials?.date_fin}
                        error={credentialsError.date_fin}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("date_fin", null)}
                        name={"date_fin"}
                        className={"w-full xl:ml-1"}

                    />
                </div>

                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>

        {!check_activite && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_sous_activites(activite); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <AiOutlineDelete/>
                <span className='ml-1'>Supprimer</span>
                <span className='ml-1 text-dark'>(non recommandé)</span>
            </button>
        </div>}
    </div>
    </MainContent>
  )
}

export default SousActiviteForm