import React from 'react'
import Navigations from './Navigations'

const MainContent = ({children, title}) => {
  return (
    <div>
        <Navigations title={title}/>
        <div className='flex justify-center items-center pt-20'>
            <div className='container px-2 md:px-0'>
                {children}
            </div>
        </div>
    </div>
  )
}

export default MainContent