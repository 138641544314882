import React from 'react';

function WeatherIcon({ icon }) {
  // Construisez l'URL complète pour l'icône en utilisant le code fourni par l'API OpenWeather

  const iconUrl = require(`../../assets/images/icons/${icon}.png`)

  return (
    <img src={iconUrl} alt="Weather Icon" className='w-[70px]'/>
  );
}

export default WeatherIcon;
