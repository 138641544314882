import React from 'react'
import { LinkBtn, MainContent } from '../../../components'
import { Link, useParams } from 'react-router-dom';
import { usePlateformContext } from '../../../contexts';
import { AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai';
import { check_admin } from '../../../constances';

const FeuilleDeRouteView = () => {

    const {id_unite_gestion} = useParams();
    const {feuilleDeRoutes, user} = usePlateformContext();

    let feuilleDeRoutes_data = feuilleDeRoutes.filter(item => item.unite_gestion.toString() === id_unite_gestion) 


  return (
    <MainContent title={`Feuille de route de l’expert en suivi évaluation`}>
        {/* Entete */}
        {check_admin(user) && <div className='flex items-center mt-5 justify-end md:flex-row flex-col px-2 md:px-1 py-4'>
            <LinkBtn
                description={"Feuille de route"}
                icon={<AiOutlinePlusCircle/>}
                path={`/unites-gestions/${id_unite_gestion}/plans-actions/feuille-de-route/nouvelle`}
                className={'bg-color-plt-1 text-light w-[100%] md:w-auto mb-2 md:mb-0'}
            />
        </div>}

        {/* Tableau des composantes sous-composantes et volets d'activites */}
        <div className='container px-2 p-4'>
            {/* Composantes */}
            <div className='table-container mb-10'>
                <table className='table'>
                    <thead className='bg-dark text-light'>
                        <th>N°</th>
                        <th>Feuille de route</th>
                        <th>Description</th>
                    </thead>
                    <tbody>
                        {feuilleDeRoutes_data.map((item, index) => {
                            return(
                                <tr>
                                    <td className='w-[50px]'>{index + 1}</td>
                                    <td className='whitespace-normal'>
                                        Feuille de route de l’expert en suivi évaluation <b>({item.annee})</b> -   
                                         <Link to={`/unites-gestions/${id_unite_gestion}/plans-actions/${item.id}`} className='text-color-plt-5 font-semibold'> Plan d'actions</Link>
                                    </td>
                                    <td className='whitespace-normal'>{item.description}</td>
                                    {check_admin(user) && <td className='w-[90px]'>
                                        <span className='flex'>
                                            <LinkBtn
                                                description={"Modifier"}
                                                icon={<AiOutlineEdit/>}
                                                className={"h-5"}
                                                path={`/unites-gestions/${id_unite_gestion}/plans-actions/feuille-de-route/${item.id}`}
                                            />
                                        </span>
                                    </td>}
                                </tr>
                            )
                        })}

                        {!feuilleDeRoutes_data.length > 0 && <tr>
                                <td colSpan={3} className='h-20 text-center'>Aucune feuille de route</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </MainContent>
  )
}

export default FeuilleDeRouteView