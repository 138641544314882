import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Axios, openWeatherApi } from '../constances';
import axios from 'axios';
import { getItem, removeItem } from '../services';

// Context Provider qui engloble toute les views de la plateforme, l'application de gestion 


const PlateformContext = createContext();

export const PlateformeContextProvider = ({children}) => {

    const [formLoading, setFormLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [user, setUser] = useState({});

    let config_header_permission = {
        headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
        }
    };

    // La variable d'authentification
    const [isAuthenticated, setIsAuthenticated] = useState(
        () => {
            let token_storage = getItem('token');
            let user_storage = getItem('user')

            if (!!token_storage && !!user_storage) {
                setToken(token_storage);
                setUser(user_storage);

                // recuperer les datas
                // fetchData();

                return true
            }else{
                removeItem('token');
                removeItem('user');
                return false
            }
        }
    );

    const [abonnement_last, setAbonnementLast] = useState({});
    const [abonnement_list, setAbonnementList] = useState([]);

    const [unites_gestions, setUnitesGestions] = useState([]);
    const [composantes, setComposantes] = useState([]);
    const [sousComposantes, setSousComposantes] = useState([]);
    const [voletsActivites, setVoletsActivites] = useState([]);
    const [indicateurs, setIndicateurs] = useState([]);
    const [feuilleDeRoutes, setFeuilleDeRoutes] = useState([]);
    const [activitesPlanAction, setActivitesPlanAction] = useState([]);
    const [planActions, setPlanActions] = useState([]);
    const [ptba, setPtba] = useState([]);
    const [categorieDepenses, setCategorieDepenses] = useState([]);
    const [resultatsAttendus, setResultatsAttendus] = useState([]);
    const [taches, setTaches] = useState([]);
    const [ressourcesBudgets, setRessourcesBudgets] = useState([]);
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [activites, setActivites] = useState([]);
    const [sousActivites, setSousActivites] = useState([]);


    // Fonctions Get Datas all
    const get_abonnement_last = async (tokenValue) => {
        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/abonnements/last-details`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setAbonnementLast(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const get_abonnement_list = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/abonnements/liste`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setAbonnementList(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_unites_gestion = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/unites-gestions/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setUnitesGestions(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_activtes_plan_actions = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/plans-actions/activites-plan-action`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setActivitesPlanAction(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_plan_actions = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/plans-actions/plans-actions`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setPlanActions(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_ptba = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/sous-activites-ptba/ptba/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setPtba(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_activites = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/composantes/activites/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setActivites(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_sous_activites = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/sous-activites-ptba/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setSousActivites(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_categorie_depenses = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/categorie-depenses/categories/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setCategorieDepenses(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_resultat_attendu = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/sous-activites-ptba/resultats/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setResultatsAttendus(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_taches = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/sous-activites-ptba/taches/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setTaches(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_ressources_budgets = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/sous-activites-ptba/ressources/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setRessourcesBudgets(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_evolution_tache = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/sous-activites-ptba/evolutions/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setTaches(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_utilisateurs = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/users/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setUtilisateurs(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_composantes = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/composantes/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setComposantes(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_sous_composante = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/composantes/sous-composantes/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setSousComposantes(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_volet_activite = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/composantes/volets-activites/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setVoletsActivites(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };
    
    const get_indicateurs = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/indicateurs/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setIndicateurs(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    const get_feuille_de_route = async (tokenValue) => {

        setIsLoading(true);

        try {
            const response = await Axios.get(`/api/plans-actions/feuille-de-route`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenValue}`
                }
            });

            if (response) {
                setFeuilleDeRoutes(response.data);

                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

    // Recuperer toutes les donnees avec la fonction ci dessous
    const get_data = async (token) => {
        await get_unites_gestion(token);
        await get_composantes(token);
        await get_volet_activite(token);
        await get_activites(token);
        await get_sous_activites(token);
        await get_sous_composante(token);
        await get_abonnement_last(token);
        // await get_indicateurs(token);
        // await get_feuille_de_route(token);
        // await get_activtes_plan_actions(token);
        // await get_plan_actions(token);
        await get_ptba(token);
        await get_categorie_depenses(token);
        // await get_resultat_attendu(token);
        // await get_taches(token);
        // await get_ressources_budgets(token);
        await get_utilisateurs(token);
        await get_abonnement_list(token);
    }



    const create_unites_gestion = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/unites-gestions/`, inputs , config_header_permission);

            if (response) {
                setUnitesGestions(response.data);

                response_api = true;
                
                await get_data(token);

                setFormLoading(false);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_unites_gestion = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/unites-gestions/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setUnitesGestions(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_unites_gestion = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/unites-gestions/${inputs.id}/`, config_header_permission);

            if (response) {

                setUnitesGestions(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                console.log(response)

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // Composantes
    const create_composante = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/composantes/`, inputs , config_header_permission);

            if (response) {
                setComposantes(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_composante = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/composantes/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setComposantes(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_composante = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/composantes/${inputs.id}/`, config_header_permission);

            if (response) {
                setComposantes(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // Sous composantes
    const create_sous_composante = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/composantes/sous-composantes/`, inputs , config_header_permission);

            if (response) {
                setSousComposantes(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_sous_composante = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/composantes/sous-composantes/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setSousComposantes(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_sous_composante = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/composantes/sous-composantes/${inputs.id}/`, config_header_permission);

            if (response) {
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // Volet d'activite
    const create_volet_activite = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/composantes/volets-activites/`, inputs , config_header_permission);

            if (response) {
                setVoletsActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_volet_activite = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/composantes/volets-activites/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setVoletsActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_volet_activite = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/composantes/volets-activites/${inputs.id}/`, config_header_permission);

            if (response) {
                setVoletsActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // Activite
    const create_activites = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/composantes/activites/`, inputs , config_header_permission);

            if (response) {
                setActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_activites = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/composantes/activites/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_activites = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/composantes/activites/${inputs.id}/`, config_header_permission);

            if (response) {
                setActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // Indicateurs
    const create_indicateur = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/indicateurs/`, inputs , config_header_permission);

            if (response) {
                setIndicateurs(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_indicateur = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/indicateurs/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setIndicateurs(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_indicateur = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/indicateurs/${inputs.id}/`, config_header_permission);

            if (response) {
                setIndicateurs(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // Plan d'action - feuille de route
    const create_feuille_de_route = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/plans-actions/feuille-de-route`, inputs , config_header_permission);

            if (response) {
                setFeuilleDeRoutes(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_feuille_de_route = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/plans-actions/feuille-de-route/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setFeuilleDeRoutes(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_feuille_de_route = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/plans-actions/feuille-de-route/${inputs.id}/`, config_header_permission);

            if (response) {
                setFeuilleDeRoutes(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // Activite plan d'actions
    const create_activtes_plan_actions = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/plans-actions/activites-plan-action`, inputs , config_header_permission);

            if (response) {
                setActivitesPlanAction(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_activtes_plan_actions = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/plans-actions/activites-plan-action/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setActivitesPlanAction(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_activtes_plan_actions = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/plans-actions/activites-plan-action/${inputs.id}/`, config_header_permission);

            if (response) {
                setActivitesPlanAction(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // Plan d'actions
    const create_plan_actions = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/plans-actions/plans-actions`, inputs , config_header_permission);

            if (response) {
                setPlanActions(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_plan_actions = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/plans-actions/plans-actions/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setPlanActions(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_plan_actions = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/plans-actions/plans-actions/${inputs.id}/`, config_header_permission);

            if (response) {
                setPlanActions(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // PTBA
    const create_ptba = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/sous-activites-ptba/ptba/`, inputs , config_header_permission);

            if (response) {
                setPtba(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_ptba = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/sous-activites-ptba/ptba/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setPtba(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_ptba = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/sous-activites-ptba/ptba/${inputs.id}/`, config_header_permission);

            if (response) {
                setPtba(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // activite
    const create_sous_activites = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/sous-activites-ptba/`, inputs , config_header_permission);

            if (response) {
                setSousActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_sous_activites(token)

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_sous_activites = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/sous-activites-ptba/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setSousActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_sous_activites = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/sous-activites-ptba/${inputs.id}/`, config_header_permission);

            if (response) {
                setSousActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // Categorie de depenses
    const create_categorie_depenses = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/categorie-depenses/categories/`, inputs , config_header_permission);

            if (response) {
                setCategorieDepenses(response.data);
                setFormLoading(false);
                response_api = true;

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_categorie_depenses = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/categorie-depenses/categories/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setCategorieDepenses(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_categorie_depenses = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/categorie-depenses/categories/${inputs.id}/`, config_header_permission);

            if (response) {
                setCategorieDepenses(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };


    // Resultat attendu de l'activite
    const create_resultat_attendu = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/sous-activites-ptba/resultats/`, inputs , config_header_permission);

            if (response) {
                setResultatsAttendus(response.data);
                setFormLoading(false);
                response_api = true;

                await get_data(token);
                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_resultat_attendu = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/sous-activites-ptba/resultats/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setResultatsAttendus(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_resultat_attendu = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/sous-activites-ptba/resultats/${inputs.id}/`, config_header_permission);

            if (response) {
                setResultatsAttendus(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };


    // taches de l'activite
    const create_taches = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/sous-activites-ptba/taches/`, inputs , config_header_permission);

            if (response) {
                setTaches(response.data);
                setFormLoading(false);
                response_api = true;

                await get_data(token);
                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_taches = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/sous-activites-ptba/taches/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setTaches(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_taches = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/sous-activites-ptba/taches/${inputs.id}/`, config_header_permission);

            if (response) {
                setTaches(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };


    // Ressource budget d'une tache
    const create_ressources_budgets = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/sous-activites-ptba/ressources/`, inputs , config_header_permission);

            if (response) {
                setRessourcesBudgets(response.data);
                get_activites(token);
                setFormLoading(false);
                response_api = true;

                await get_data(token);
                
                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_ressources_budgets = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/sous-activites-ptba/ressources/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setRessourcesBudgets(response.data);
                get_activites(token);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_ressources_budgets = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/sous-activites-ptba/ressources/${inputs.id}/`, config_header_permission);

            if (response) {
                setRessourcesBudgets(response.data);
                get_activites(token);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };


    // Evolution de tache
    const create_evolution_tache = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/sous-activites-ptba/evolutions/`, inputs , config_header_permission);

            if (response) {
                setTaches(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_evolution_tache = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/sous-activites-ptba/evolutions/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setSousActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_evolution_tache = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/sous-activites-ptba/evolutions/${inputs.id}/`, config_header_permission);

            if (response) {
                setSousActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // Validation
    const create_validation_tache = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/sous-activites-ptba/validations/`, inputs , config_header_permission);

            if (response) {
                setSousActivites(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Enregistré avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };


    // Utilisateurs
    const create_utilisateurs = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/users/`, inputs , config_header_permission);

            if (response) {
                setUtilisateurs(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_utilisateurs = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.put(`/api/users/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                setUtilisateurs(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Modifié avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_utilisateurs = async (inputs) => {

        let response_api = false;
        setFormLoading(true);

        try {
            const response = await Axios.delete(`/api/users/${inputs.id}/`, config_header_permission);

            if (response) {
                setUtilisateurs(response.data);
                response_api = true;
                setFormLoading(false);

                await get_data(token);

                toast.success("Supprimer avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };



    // Weather API
    const [weatherData, setWeatherData] = useState({});

    let latitude = '-4.2536752';
    let longitude = '15.2311941';

    let [userCity, setUserCity] = useState('Brazzaville');
    let [userCountry, setUserCountry] = useState('Congo');
    

    // Fonction pour obtenir les données météorologiques
    
    const getWeatherData = () => {
        axios
        .get(`${openWeatherApi.base}weather?lat=${latitude}&lon=${longitude}&APPID=${openWeatherApi.key}&units=metric`)
        .then((response) => {
            setWeatherData(response.data);
        })
        .catch((error) => {
            console.error('Erreur lors de la récupération des données météorologiques :', error);
        });
    };


    // Gestion d'authentification
    const login = async (inputs) => {

        setFormLoading(true);
        let response_api = false; 

        await Axios.post(`/api/users/login/`, inputs)

        .then(response => {
                if (response.data.sending_otp) {
                    // Envoie de l'otp
                    response_api = {
                        sending_otp: true,
                    }

                    toast.success("Votre code OTP a été envoyé avec succès. Veuillez consulter votre messagerie pour récupérer le code.");
                }else{
                    // Connexion direct
                    setUser(response.data.user);

                    localStorage.setItem('user', JSON.stringify(response.data.user));

                    response_api = {
                        sending_otp: false,
                    }

                    setIsAuthenticated(true);
                }

                setToken(response.data.token);
                localStorage.setItem('token', JSON.stringify(response.data.token));

                setFormLoading(false);
        })

        .catch(error => {
            console.error(error);
            setFormLoading(false);
            toast.error("Une erreur d'authentification s'est produite. Veuillez vérifier vos informations et réessayer.");
        }) 

        return response_api;
    };

    // Gestion d'authentification
    const forgetInformations = async (inputs) => {

        setFormLoading(true);
        let response_api = false; 

        await Axios.post(`/api/users/forget-informations/`, inputs)

        .then(response => {
            response_api = true;
            setFormLoading(false);

            toast.success("Veuillez vérifier votre boite de recption e-mail, vos informations de connexion.");
        })

        .catch(error => {
            console.error(error);
            setFormLoading(false);
            toast.error("Oups !. Veuillez vérifier votre adresse e-mail et réessayer tout en etant sur que cette adresse a bien un compte sur sspp.");
        }) 

        return response_api;
    };

    const otp_verification = async (inputs) => {

        setFormLoading(true);

        try {
            const response = await Axios.post(`/api/users/otp-verification/`, inputs , config_header_permission);

            if (response) {
                setUser(response.data.user);
                localStorage.setItem('user', JSON.stringify(response.data.user));

                // Faire appel aux donnees
                get_data(token);
            }
            toast.success("Votre code OTP a été vérifié avec succès et vous êtes maintenant connecté.");
            setFormLoading(false);
            setIsAuthenticated(true);

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            toast.error("Nous sommes désolés, mais votre code OTP n'a pas pu être vérifié. Veuillez vérifier que vous avez saisi correctement le code et réessayer.");
        }
    };

    const password_edit = async (inputs) => {

        setFormLoading(true);
        let response_api = false;

        try {
            const response = await Axios.put(`/api/users/edit-password/`, inputs , config_header_permission);
            
            console.log(response.data)
            if (response) {
                setUser(response.data);
                localStorage.setItem('user', JSON.stringify(response.data));
            }

            toast.success("Enregistré avec succès");
            setFormLoading(false);
            response_api = true;

        } catch (error) {
            console.error(error);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
            setFormLoading(false);
        }

        return response_api;
    };

    const logout = async () => {

        setIsLoading(true);

        try {
            // const response = await Axios.post(`/api/users/logout/`, {} , config_header_permission);

            const response = true;

            setTimeout(() => {
                if (response) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    setIsAuthenticated(false);
                    setUser({});
                    setToken(null);
                    toast.success("Vous êtes deconnecté.");
                    setIsLoading(false);
                }
            }, 5000);

        } catch (error) {
            console.error(error);

            setIsLoading(false);
        }
    };

  
    // lancer lors de l'ouverture de l'application
    useEffect(() => {
        const isLoggedIn = async () => {
            try {
                let token_storage = getItem('token');
                let user_storage = getItem('user')
    
                if (!!token_storage && !!user_storage) {
                    setToken(token_storage);
                    setUser(user_storage);
                    setIsAuthenticated(true);
    
                    // recuperer les datas
                    // get Datas
                    get_data(token_storage);
                    
                    
    
                    return true
                }else{
                    removeItem('token');
                    removeItem('user');
                    setIsAuthenticated(false);
    
                    return false
                }
      
            } catch (error) {
                console.log(`User not connected`);
                return false;
            }
        }

        isLoggedIn();
    }, [])

    useEffect(() => {
        // Appelez la fonction pour obtenir les données météorologiques au montage du composant
        getWeatherData();
    
        // Actualisez les données toutes les 30 minutes
        const intervalId = setInterval(() => {
          getWeatherData();
          console.log("Get Data Weather");
        }, 30 * 60 * 1000); // 30 minutes en millisecondes
    
        // Nettoyez l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        // Vérifiez si la géolocalisation est disponible dans le navigateur
        if ("geolocation" in navigator) {
            // Obtenir la géolocalisation de l'utilisateur
            navigator.geolocation.getCurrentPosition(function(position) {
            
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
        
            // Effectuer une recherche inverse de géocodage pour obtenir la ville et le pays
            fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`)
                .then(response => response.json())
                .then(data => {

                setUserCity(data.address.state);
                setUserCountry(data.address.country);
                
                // Vous pouvez utiliser city et country comme bon vous semble ici
                // console.log(`Ville : ${userCity}, Pays : ${userCountry}`);
                // console.log('DATA', data)
                })
                .catch(error => {
                console.error('Erreur de géocodage inverse :', error);
                });
            }, function(error) {
            // En cas d'erreur de géolocalisation, vous pouvez gérer l'erreur ici
            console.error('Erreur de géolocalisation :', error);
            });
        } else {
            // Si la géolocalisation n'est pas prise en charge, gérez-le ici
            console.log("La géolocalisation n'est pas disponible dans ce navigateur.");
        }
    }, [])


    let value = {
        // Variables
        weatherData,
        userCity,
        userCountry,
        abonnement_last,
        abonnement_list,

        unites_gestions,
        voletsActivites,
        activites,
        composantes,
        sousComposantes,
        sousActivites,
        ressourcesBudgets,
        indicateurs,
        feuilleDeRoutes,
        activitesPlanAction,
        planActions,
        ptba,
        categorieDepenses,
        resultatsAttendus,
        taches,

        create_unites_gestion,
        update_unites_gestion,
        delete_unites_gestion,

        create_indicateur,
        update_indicateur,
        delete_indicateur,

        create_feuille_de_route,
        update_feuille_de_route,
        delete_feuille_de_route,

        create_activtes_plan_actions,
        update_activtes_plan_actions,
        delete_activtes_plan_actions,

        create_plan_actions,
        update_plan_actions,
        delete_plan_actions,

        create_ptba,
        update_ptba,
        delete_ptba,

        create_activites,
        update_activites,
        delete_activites,

        create_sous_activites,
        update_sous_activites,
        delete_sous_activites,

        create_categorie_depenses,
        update_categorie_depenses,
        delete_categorie_depenses,

        create_resultat_attendu,
        update_resultat_attendu,
        delete_resultat_attendu,

        create_taches,
        update_taches,
        delete_taches,

        create_ressources_budgets,
        update_ressources_budgets,
        delete_ressources_budgets,

        create_evolution_tache,
        update_evolution_tache,
        delete_evolution_tache,

        create_validation_tache,

        get_utilisateurs,
        create_utilisateurs,
        update_utilisateurs,
        delete_utilisateurs,

        
        token,
        utilisateurs,
        isAuthenticated,
        formLoading,
        isLoading,
        user,


        // Fonctions
        login,
        logout,
        otp_verification,
        password_edit,
        forgetInformations,

        create_composante,
        update_composante,
        delete_composante,
        
        create_sous_composante,
        update_sous_composante,
        delete_sous_composante,

        create_volet_activite,
        update_volet_activite,
        delete_volet_activite,
        
    }

    return(
        <PlateformContext.Provider value={value}>{children}</PlateformContext.Provider>
    )
}

export const usePlateformContext = () => useContext(PlateformContext);