import React, { useState } from 'react';
import './FormInput.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const FormInput = ({
  className, 
  error, 
  label, 
  name, 
  type, 
  placeholder, 
  required,
  value,
  onChange,
  onFocus = () => {},
  disabled=false,
  autoComplete='on',
  password
}) => {

  const [visiblePassword, setVisiblePassword] = useState(false);

  return (
    <div className={`${className} form-input my-2`}>

        <span className='form-label text-dark'>{label}<span>{required && '*'}</span></span>

        <div className={`form-input-section rounded-md relative ${error && 'error'}`}>
            <input 
                type={visiblePassword ? 'text' : type}
                name={name}
                placeholder={placeholder}
                className='form-input-section-input rounded-md focus:border-color-plt-1 text-dark'
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                disabled={disabled}
                autoComplete={autoComplete}
            />

            {password && <span className='absolute px-3 cursor-pointer right-0' onClick={() => setVisiblePassword(!visiblePassword)}>
              {visiblePassword ? <FaEyeSlash/> : <FaEye/>}
            </span>}
        </div>

        <span className='form-input-error'>{error}</span>
    </div>
  )
}

export default FormInput