import React from 'react'
import { LinkBtn, MainContent } from '../../../components'
import { AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai';
import { usePlateformContext } from '../../../contexts';
import { useParams } from 'react-router-dom';
import { FormatPrice, check_admin, getElementData } from '../../../constances';

const CategorieDepenseView = () => {

    const {id_unite_gestion} = useParams();
    const {categorieDepenses, unites_gestions, user} = usePlateformContext();

    let categorie_depenses_data = categorieDepenses.filter(item => item.unite_gestion.toString() === id_unite_gestion) 
    let unite_gestion = getElementData(id_unite_gestion, unites_gestions)

    let total_montant_aloue = 0
    let total_montant = 0
    let total_montant_devise = 0
    let total_pourcentage = 0

  return (
    <MainContent title={`Catégorie de dépenses`}>
        {/* Entete */}
        {check_admin(user) && <div className='flex items-center mt-5 justify-end md:flex-row flex-col px-2 md:px-1 py-4'>
            <LinkBtn
                description={"Catégorie de dépenses"}
                icon={<AiOutlinePlusCircle/>}
                path={`/unites-gestions/${id_unite_gestion}/categorie-depenses/nouvelle`}
                className={'bg-color-plt-1 text-light w-[100%] md:w-auto mb-2 md:mb-0'}
            />
        </div>}

        {/* Tableau des composantes sous-composantes et volets d'activites */}
        <div className='container px-2 p-4'>
            {/* Composantes */}
            <div className='table-container mb-10'>
                <table className='table'>
                    <thead className='bg-dark text-light'>
                        <th>N°</th>
                        <th>Catégorie</th>
                        {/* <th className='whitespace-normal'>Montant Alloué au titre du prêt (en {unite_gestion?.devise})</th> */}
                        <th>Montant ({"FCFA"})</th>
                        <th>Montant ({unite_gestion?.devise})</th>
                        <th className='text-center'>%</th>
                    </thead>
                    <tbody>
                        {categorie_depenses_data.map((item, index) => {

                            let pourcentage = parseFloat(((item.montant_devise / unite_gestion?.cout_total_devise)) * 100).toFixed(2)
                            
                            total_pourcentage = total_pourcentage + parseFloat(pourcentage)

                            total_montant = total_montant + item.montant_devise_locale
                            total_montant_devise = total_montant_devise + parseInt(parseFloat(item.montant_devise).toFixed(0))
                            total_montant_aloue = total_montant_aloue + parseFloat(item.montant_alloue_au_titre_du_pret)

                            return(
                                <tr key={index}>
                                    <td className='w-[50px]'>{index + 1}</td>
                                    <td className='whitespace-normal'>{item.designation}</td>
                                    {/* <td className='whitespace-normal'>{FormatPrice(item.montant_alloue_au_titre_du_pret)}</td> */}
                                    <td className='whitespace-normal'>{FormatPrice(item.montant_devise_locale)}</td>
                                    <td className='whitespace-normal'>{FormatPrice(parseFloat(item.montant_devise).toFixed(0))}</td>
                                    <td className='text-center'>
                                        { unite_gestion?.cout_total_devise ? parseFloat(pourcentage).toFixed(2) : '-'}
                                    </td>
                                    {check_admin(user) && <td className='w-[90px]'>
                                        <span className='flex'>
                                            <LinkBtn
                                                description={"Modifier"}
                                                icon={<AiOutlineEdit/>}
                                                className={"h-5"}
                                                path={`/unites-gestions/${id_unite_gestion}/categorie-depenses/${item.id}`}
                                            />
                                        </span>
                                    </td>}
                                </tr>
                            )
                        })}

                        {categorie_depenses_data.length > 0 && <tr className='font-medium' style={{backgroundColor: "#C5E0B3"}}>
                            <td colSpan={2}>Total</td>
                            <td>{FormatPrice(total_montant)}</td>
                            <td>{FormatPrice(parseFloat(total_montant_devise))}</td>
                            <td className='text-center'>{parseFloat(total_pourcentage).toFixed(2)}</td>
                            {check_admin(user) && <td className='text-center'>-</td>}
                            </tr>}

                        {!categorie_depenses_data.length > 0 && <tr>
                                <td colSpan={5} className='h-20 text-center'>Aucune catégorie de dépenses</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </MainContent>
  )
}

export default CategorieDepenseView