import React from 'react'
import { Button, Input, MainContent, Select, Textarea } from '../../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../../contexts';
import { getElementData } from '../../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const ActiviteTacheForm = () => {

    const navigate = useNavigate();
    const {id_unite_gestion, id_activite, id_tache} = useParams();
    const {taches, activites, update_taches, create_taches, delete_taches} = usePlateformContext();
    
    let check_tache = id_tache === 'nouvelle';
    let tache_selected = getElementData(id_tache, taches);


    let model_data = {
        activite: id_activite,
        designation: "",
        duree: "",
        date_debut: "",
        date_fin: "",
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.designation) {
                errors.designation = "Entrer la designation";
            }

            if (!credentials.duree) {
                errors.duree = "Entrer la duree";
            }

            if (!credentials.date_debut) {
                errors.date_debut = "Entrer la date de debut";
            }

            if (!credentials.date_fin) {
                errors.date_fin = "Entrer la date de fin";
            }
           

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_tache ? await update_taches(credentials) : await create_taches(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_tache ? model_data : tache_selected);
    }, [taches])


  return (
    <MainContent title={`${check_tache ? 'Créer une nouvelle tâche' : `${tache_selected?.designation}`.substring(0, 70) + '...'}`}>
        <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>
                <Input
                    type={"text"}
                    label={"Designation"}
                    required={true}
                    placeholder={"Designation"}
                    autoComplete='off'
                    value={credentials?.designation}
                    error={credentialsError.designation}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("designation", null)}
                    name={"designation"}
                />

                <Input
                    type={"number"}
                    label={"Durée (semaine)"}
                    required={true}
                    placeholder={"Durée (semaine)"}
                    autoComplete='off'
                    value={credentials?.duree}
                    error={credentialsError.duree}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("duree", null)}
                    name={"duree"}
                />

                <Input
                    type={"date"}
                    label={"Date de debut"}
                    required={true}
                    placeholder={"Date de debut"}
                    autoComplete='off'
                    value={credentials?.date_debut}
                    error={credentialsError.date_debut}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("date_debut", null)}
                    name={"date_debut"}
                />

                <Input
                    type={"date"}
                    label={"Date de fin"}
                    required={true}
                    placeholder={"Date de fin"}
                    autoComplete='off'
                    value={credentials?.date_fin}
                    error={credentialsError.date_fin}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("date_fin", null)}
                    name={"date_fin"}
                />


                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>

        {!check_tache && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_taches(tache_selected); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <AiOutlineDelete/>
                <span className='ml-1'>Supprimer</span>
                <span className='ml-1 text-dark'>(non recommandé)</span>
            </button>
        </div>}
    </div>
    </MainContent>
  )
}

export default ActiviteTacheForm