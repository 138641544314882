import React, { useEffect, useState } from 'react'
import { usePlateformContext } from '../../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { AuthMain, Button, Input } from '../../components';

const OTPView = () => {

    const {otp_verification, isAuthenticated} = usePlateformContext();

    const navigate = useNavigate();

    let model_data = {
        otp_code: "",
    };


    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        // Validation du formulaire

        const errors = {}; // Objet pour stocker les erreurs

        if (!credentials.otp_code) {
            errors.otp_code = "Entrer le code OTP";
        }

        if (Object.keys(errors).length > 0) {
            setCredentialsError(errors);
        } else {
            await otp_verification({otp_code: credentials.otp_code});
        }
        
    }

    useEffect(() => {
        if(isAuthenticated){
            navigate('/')
        }
    }, [navigate, isAuthenticated])

  return (
    <AuthMain>
        <form onSubmit={handleSubmit} className='w-[400px] max-w-[95%] bg-white p-3 rounded-md'> 
            <div className='mb-3'>
                <h3 className='text-xl mb-1 text-secondary'>Autehntification OTP</h3>
                <p className='text-dark text-sm font-normal'>Veuillez entrer le code à quatre chiffres qui vous a été envoyé. Veuillez vérifier vos e-mails.</p>
            </div>

            <div>
            <Input
                type={"number"}
                label={"Code OTP"}
                required={true}
                placeholder={"9999"}
                autoComplete='off'
                value={credentials.otp_code}
                error={credentialsError.otp_code}
                onChange={handleChange}
                onFocus={() => handleChangeError("otp_code", null)}
                name={"otp_code"}
            />

            <Button
                type="submit"
                text={"Verifier l'OTP"}
                bgColor={"bg-color-plt-1"}
            />

            </div>

            <div className='mt-5'>
                <p className="text-normal text-dark text-sm">{"N'avez-vous pas reçu le code ?"} <Link to={`/login`} className="text-color-plt-1 font-medium cursor-pointer">Renvoyez le code.</Link></p>
            </div>
        </form>
    </AuthMain>
  )
}

export default OTPView