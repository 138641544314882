import React, { useEffect, useState } from 'react'
import { AcivitesTaches, ActivitesIndicateurs, ActivitesResltats, EvolutionTimeLine, LabelValue, LinkBtn, MainContent } from '../../../../components'
import { Link, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../../contexts';
import { FormatDate, FormatPrice, check_admin, check_admin_composante, getElementData } from '../../../../constances';
import { AiFillEdit, AiOutlineCheck, AiOutlinePlusCircle, AiOutlineWarning } from 'react-icons/ai';
import AcivitesRessourceBudget from '../../../../components/AcivitesRessourceBudget';

const TacheDetailsView = () => {

  const {id_activite, id_unite_gestion, id_tache} = useParams();
  const {activites, taches, user, ressourcesBudgets, unites_gestions, create_validation_tache} = usePlateformContext();

  let activite = getElementData(id_activite, activites);
  let tache = getElementData(id_tache, taches);
  let unite_gestion = getElementData(id_unite_gestion, unites_gestions);

  let ressources_Budgets_attendus_data = ressourcesBudgets.filter(item => item.tache.toString() === id_tache)

  const hundleValidation = async (evolution, value) => {
    await create_validation_tache({
      evolution: evolution,
      user: user?.id,
      validated: value
    })
  }
  
  return (
    <MainContent title={`Tâche : ${tache?.designation?.substring(0, 70) + '...'}`}>
        <div className='container flex flex-col md:flex-row items-start justify-between flex-grow mt-5'>
          <div className='flex-grow w-[100%] md:w-[60%]'>

            <div className='bg-white p-2 rounded-md mb-4'>
              <LabelValue
                label={"Activité"}
                value={`${activite?.designation}`}
              />

              <div className='table-container py-3'>
                <table className='table'>
                    <thead className='bg-gray-700 text-light'>
                        <tr>
                            <th>Nom de la tâche</th>
                            <th>Durée (semaine)</th>
                            <th>Date début</th>
                            <th>Date fin</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td className='whitespace-normal'>
                              {tache?.designation}
                          </td>
                          <td className='text-center'>{tache?.duree}</td>
                          <td className='text-center capitalize'>{FormatDate(tache?.date_debut)}</td>
                          <td className='text-center capitalize'>{FormatDate(tache?.date_fin)}</td>

                          <td className='w-[50px]'>
                              <span className='flex'>
                                  <LinkBtn
                                      icon={<AiFillEdit/>}
                                      className={"text-color-plt-1 text-md"}
                                      path={`/unites-gestions/${id_unite_gestion}/activites/${id_activite}/taches/${tache?.id}`}
                                  />
                              </span>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
          </div>

            <div className='bg-white p-2 rounded-md mb-4'>
              <div className='flex items-center justify-start py-0'>
                <span className='font-bold text-sm text-color-plt-1'>Avancement de la tâche</span>

                {check_admin_composante(user, activite?.composante) && <LinkBtn
                    icon={<AiOutlinePlusCircle/>}
                    className={"text-color-plt-1 text-lg"}
                    path={`/unites-gestions/${id_unite_gestion}/activites/${id_activite}/taches/details/${tache?.id}/evolution/nouvelle`}
                />}
              </div>

              {tache?.evolutions_tache?.map((item, index) => {

                let bgTimeLine = 'text-color-plt-2'

                if (item.pourcentage < 20) {
                  bgTimeLine = 'text-color-plt-6'
                } else if (item.pourcentage < 70) {
                  bgTimeLine = 'text-color-plt-4'
                }else {
                  bgTimeLine = 'text-color-plt-2'
                }

                return(
                  <div key={index} className={`border-[1px] ${index === 0 ? 'mt-0' : 'mt-6'} mb-2 p-1 rounded relative`}>

                      {(check_admin_composante(user, activite?.composante) && item.validated === null) && <div className='flex justify-end absolute top-0 right-0'>
                        <LinkBtn
                            icon={<AiFillEdit/>}
                            className={"text-color-plt-1 text-lg"}
                            path={`/unites-gestions/${id_unite_gestion}/activites/${id_activite}/taches/details/${tache?.id}/evolution/${item.id}`}
                        />
                      </div>}

                      <div>
                        <span className='font-medium text-sm'>Evolution (sur 100%) : </span>
                        <span className={`${bgTimeLine} font-bold`}>{item.pourcentage}% ({item.status})</span>
                      </div>

                      <div className='my-2'>
                        <span className='font-medium text-sm'>Commentaire : </span>

                        <div>
                          <p>{item.commentaire}</p>
                        </div>
                      </div>

                      {item.fichier && <div>
                        <span className='font-medium text-sm'>Fichier joint : </span>
                        <div>
                          <a href={item.fichier} download>{item.fichier}</a>
                        </div>
                      </div>}

                      {(check_admin(user) && item.validated === null) && <div className='flex justify-between mt-3'>
                        <button onClick={() => hundleValidation(item.id, false)} className='w-[49%] h-10 text-color-plt-6 border-color-plt-6 rounded border-[1px] flex justify-center items-center'>
                          <AiOutlineCheck/>
                          <span className='ml-2 text-md'>Désapprouver</span>
                        </button>

                        <button onClick={() => hundleValidation(item.id, true)} className='w-[49%] h-10 text-color-plt-1 border-color-plt-1 rounded border-[1px] flex justify-center items-center'>
                          <AiOutlineCheck/>
                          <span className='ml-2 text-md'>Approuver</span>
                        </button>
                      </div>}

                      {(item.validated !== null) && <div className='flex justify-between mt-3'>
                        {item.validated ? <div className='w-full border-[1px] h-10 rounded flex items-center justify-center text-[gray]'>
                          <AiOutlineCheck/>
                          <span className='text-sm ml-2'>Approuvé</span>
                        </div> : 
                        
                        <div className='w-full border-[1px] h-10 rounded flex items-center justify-center text-[gray]'>
                          <AiOutlineCheck/>
                          <span className='text-sm ml-2'>Désapprouvé</span>  
                        </div>}
                      </div>}

                      {(user.profile !== "client-admin" && item.validated === null) && <div className='w-full border-[1px] h-10 rounded flex items-center justify-center text-[gray]'>
                          <AiOutlineCheck/>
                          <span className='text-sm ml-2'>En attente de validation</span>  
                        </div>}
                  </div>)
              })}
            </div>

          </div>

          <div className='w-[100%] md:w-[40%] md:px-2'>
            <div className='bg-white rounded-md p-2 pb-0 mb-5'>

              <div className='mb-5'>
                <table className='table'>
                    <thead className='bg-color-plt-1 text-light'>
                      <tr>
                        <td className='font-bold text-sm text-center' colSpan={2}>Budget de la tâche</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='font-bold text-sm text-color-plt-1 text-center'>{FormatPrice(tache?.montant)} {"FCFA"}</td>
                        <td className='font-bold text-sm text-color-plt-1 text-center'>{FormatPrice(parseFloat(tache?.montant / unite_gestion?.valeur_devise).toFixed(0))} {unite_gestion?.devise}</td>
                      </tr>
                    </tbody>
                  </table>
              </div>

              <div className='pb-2'>
                <table className='table'>
                  <thead className='bg-color-plt-1 text-light'>
                    <tr>
                      <td className='font-bold text-sm text-center' colSpan={2}>Réalisation de la tâche</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={2} className='font-bold text-sm text-color-plt-1 text-center'>
                        <EvolutionTimeLine value={tache?.pourcentage} width='100%'/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

            
            <div className='bg-white rounded-md mb-5 p-2'>
              {ressources_Budgets_attendus_data.length > 0 && <div className='flex items-center justify-start pb-3'>
                <span className='font-bold text-sm text-color-plt-1'>Ressources (Budget en {"FCFA"})</span>
              </div>}

              {ressources_Budgets_attendus_data.length > 0 ? <div className='table-container'>
                <table className='table'>
                    <thead className='bg-gray-700 text-light'>
                        <tr>
                            <th>Tâche</th>
                            <th>Unité</th>
                            <th>Quantité</th>
                            <th>Prix U. ({"FCFA"})</th>
                            <th>Montant ({"FCFA"})</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ressources_Budgets_attendus_data.map((item, index) => {
                            return(
                                <tr key={index}>
                                    <td className='whitespace-normal'>{getElementData(item.tache, taches)?.designation}</td>
                                    <td className='text-center'>{item.unite}</td>
                                    <td className='text-center'>{item.quantite}</td>
                                    <td className='text-center'>{FormatPrice(item.prix_unitaire)}</td>
                                    <td className='text-center'>{FormatPrice(item.montant)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
              </div> : 
                <span>Aucune ressource budget</span>
              }
            </div>

          </div>
        </div>
    </MainContent>
  )
}

export default TacheDetailsView