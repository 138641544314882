import React from 'react'
import { Button, Input, MainContent, Textarea } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { getElementData } from '../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const ActivitePlanActionForm = () => {

    const navigate = useNavigate();
    const {id_unite_gestion, id_feuille_de_route, id_activite} = useParams();
    const {feuilleDeRoutes, activitesPlanAction,  unites_gestions, update_activtes_plan_actions, create_activtes_plan_actions, delete_activtes_plan_actions} = usePlateformContext();
    let check_activite = id_activite === 'nouvelle';
    
    let activite = getElementData(id_activite, activitesPlanAction);

    let model_data = {
        feuille_de_route: id_feuille_de_route,
        designation: "",
        situation_precedente: "",
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.designation) {
                errors.designation = "Entrer la designation";
            }

            if (!credentials.situation_precedente) {
                errors.situation_precedente = "Entrer la situation précédente";
            }


            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_activite ? await update_activtes_plan_actions(credentials) : await create_activtes_plan_actions(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_activite ? model_data : activite);
    }, [unites_gestions])

  return (
    <MainContent title={`${check_activite ? "Créer une activité du plan d'action" : activite?.designation.substring(0, 50)}`}>
        <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>

                <Textarea
                    label={"Désignation"}
                    required={true}
                    placeholder={"Désignation"}
                    autoComplete='off'
                    value={credentials?.designation}
                    error={credentialsError.designation}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("designation", null)}
                    name={"designation"}
                />

                <Textarea
                    label={"Situation précédente"}
                    required={true}
                    placeholder={"Situation précédente"}
                    autoComplete='off'
                    value={credentials?.situation_precedente}
                    error={credentialsError.situation_precedente}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("situation_precedente", null)}
                    name={"situation_precedente"}
                />

                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>

        {!check_activite && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_activtes_plan_actions(activite); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <AiOutlineDelete/>
                <span className='ml-1'>Supprimer</span>
                <span className='ml-1 text-dark'>(non recommandé)</span>
            </button>
        </div>}
    </div>
    </MainContent>
  )
}

export default ActivitePlanActionForm