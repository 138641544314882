import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AuthMain, Button, Input } from '../../components';
import { usePlateformContext } from '../../contexts';

const EmailView = () => {

  const {forgetInformations, isAuthenticated} = usePlateformContext();
  const navigate = useNavigate();

  let model_data = {
    email: "",
  };

  const [credentials, setCredentials] = useState(model_data);
  const [credentialsError, setCredentialsError] = useState({});

  const handleChange = (e) => {
      setCredentials({...credentials, [e.target.name] : e.target.value})
  }

  const handleChangeError = (name, value) => {
      setCredentialsError({ ...credentialsError, [name]: value });
  }

  const handleSubmit = async (e) => {
      e.preventDefault()

      // Validation du formulaire

      const errors = {}; // Objet pour stocker les erreurs

      if (!credentials.email) {
          errors.email = "Entrer l'adresse e-mail";
      }

      if (Object.keys(errors).length > 0) {
          setCredentialsError(errors);
      } else {
        let response = await forgetInformations(credentials);

        if (response) {
          navigate(`/login`);
        }
      }
      
  }

  useEffect(() => {
      if(isAuthenticated){
          navigate('/')
      }
  }, [navigate, isAuthenticated])

  return (
    <AuthMain>
      <form onSubmit={handleSubmit} className='w-[400px] max-w-[95%] bg-white p-3 rounded-md'>
        
        <div className='mb-3'>
            <h3 className='text-xl mb-1 text-secondary'>Identifiant ou mot de passe oublié</h3>
            <p className='text-dark text-sm font-normal'>Veuillez saisir votre adresse e-mail afin que nous puissions vous renvoyer vos informations de connexion.</p>
        </div>

        <div>

          <Input
            type={"email"}
            label={"E-mail"}
            required={true}
            placeholder={"exemple@site.com"}
            autoComplete='off'
            value={credentials.email}
            error={credentialsError.email}
            onChange={handleChange}
            onFocus={() => handleChangeError("email", null)}
            name={"email"}
          />

          <Button
            type="submit"
            text={"Connexion"}
            bgColor={"bg-color-plt-1"}
          />

        </div>

        <div className='mt-5'>
            <p className="text-normal text-dark text-sm">{"Avez-vous vos informations de connexion ?"} <Link to={`/login`} className="text-color-plt-1 font-medium cursor-pointer">Veuillez vous connecter.</Link></p>
        </div>
      </form>
    </AuthMain>
  )
}

export default EmailView