import React from 'react'
import { Button, Input, MainContent } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { getElementData } from '../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const FeuilleDeRouteForm = () => {

    const navigate = useNavigate();
    const {id_unite_gestion, id_feuille_de_route} = useParams();
    const {feuilleDeRoutes, unites_gestions, update_feuille_de_route, create_feuille_de_route, delete_feuille_de_route} = usePlateformContext();
    let check_id_feuille_de_route = id_feuille_de_route === 'nouvelle';
    
    let feuille_de_rout = getElementData(id_feuille_de_route, feuilleDeRoutes);

    let model_data = {
        unite_gestion: id_unite_gestion,
        annee: "",
        description: "",
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.annee) {
                errors.annee = "Entrer l'année";
            }

            if (!credentials.description) {
                errors.description = "Entrer la description";
            }


            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_id_feuille_de_route ? await update_feuille_de_route(credentials) : await create_feuille_de_route(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_id_feuille_de_route ? model_data : feuille_de_rout);
    }, [unites_gestions])

  return (
    <MainContent title={`${check_id_feuille_de_route ? 'Créer une feuille de route' : `Feuille de route (${feuille_de_rout?.annee})`}`}>
        <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>

                <Input
                    type={"number"}
                    label={"Année"}
                    required={true}
                    placeholder={"Année"}
                    autoComplete='off'
                    value={credentials?.annee}
                    error={credentialsError.annee}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("annee", null)}
                    name={"annee"}
                />

                <Input
                    type={"text"}
                    label={"Description"}
                    required={true}
                    placeholder={"Description"}
                    autoComplete='off'
                    value={credentials?.description}
                    error={credentialsError.description}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("description", null)}
                    name={"description"}
                />

                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>

        {!check_id_feuille_de_route && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_feuille_de_route(feuille_de_rout); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <AiOutlineDelete/>
                <span className='ml-1'>Supprimer</span>
                <span className='ml-1 text-dark'>(non recommandé)</span>
            </button>
        </div>}
    </div>
    </MainContent>
  )
}

export default FeuilleDeRouteForm