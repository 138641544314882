import React, { useEffect, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { usePlateformContext } from '../../contexts';
import FormTextarea from '../components-ui/form-textarea/FormTextarea';
import FormInput from '../components-ui/form-input/FormInput';

const ModalUniteGestion = ({visible, item, onClose}) => {

    const {create_unites_gestion, update_unites_gestion, delete_unites_gestion, user} = usePlateformContext();

    let model_data = {
        structure: user?.structure_info?.id,
        designation: "",
    };


    const [credentials, setCredentials] = useState(item ? item : model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e=null) => {
            // e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.designation) {
                errors.designation = "Entrer la designation";
            }
           

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = item ? await update_unites_gestion(credentials) : await create_unites_gestion(credentials);

                if (response) {
                    onClose()
                }
            }
        
    }

    console.log(credentials)

    useEffect(() => {
        setCredentials(item ? item : model_data);
    }, [item])


  return (
    <div className={`${!visible && 'hidden'} fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[1px] z-50 flex justify-center items-center`}>
        <div className='w-[600px] max-w-[90%] bg-light p-2 rounded'>
            {/* Header - entete */}
            <div>
                <h1 className='text-[16px] font-bold text-color-plt-1'>Unité de suivit du PTBA</h1>
                <p className='my-2 text-[13px] font-normal text-dark'>
                    {item ? "Apporter la mise a jour de votre unite de suivit du Programme de Travail et Budget Annuel (PTBA)" : "Créer une unite de suivit du Programme de Travail et Budget Annuel (PTBA), pour un suivit en temps reel avec vos differentes composantes.."}
                </p>
            </div>

            {/* Content */}
            <div className='my-3'>
                {/* formulaire */}
                <FormInput
                    label={"Designation"}
                    required={true}
                    placeholder={"Entrer la designation"}
                    autoComplete='off'
                    value={credentials.designation}
                    error={credentialsError.designation}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("designation", null)}
                    name={"designation"}
                    className={'rounded-md'}
                />
            </div>

            {/* Footer boutons action */}
            <div>
                <div className='w-full flex items-center justify-end'>
                    <button onClick={onClose} className='bg-color-plt-5 font-medium hover:bg-opacity-[0.90] text-light rounded-md px-5 py-2 text-[13px] mr-2'>Annuler</button>
                    <button onClick={() => handleSubmit()} className='bg-color-plt-2 font-medium hover:bg-opacity-[0.90] text-light rounded-md px-5 py-2 text-[13px]'>{item ? "Enregistrer" : "Créer l'unité de suivit"}</button>
                </div>

                {item && <div className='mt-3 w-full border-t pt-2'>
                    <button onClick={() => {delete_unites_gestion(item); onClose()}} className='text-color-plt-6 flex items-center text-[12px]'>
                        <AiOutlineDelete/>
                        <span className='ml-1'>Suprimer l'unité de gestion</span>
                        <span className='ml-1 text-dark'>(non recommendé)</span>
                    </button>

                    <p className='text-[12px] text-dark mt-1'>Cela effacera egalements toutes les composantes ainsi que les taches appartenant a cette unite.</p>
                </div>}
            </div>
        </div>
    </div>
  )
}

export default ModalUniteGestion