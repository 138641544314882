import './App.css';
import { AuthContextProvider, PlateformeContextProvider } from './contexts';
import { RouterNavigation } from './services';

function App() {
  return (
    <PlateformeContextProvider>
        <RouterNavigation/>
    </PlateformeContextProvider>
  );
}

export default App;
