import React from 'react'
import { Button, Input, LinkBtn, MainContent, Select } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { FormatPrice, getElementData } from '../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineDelete, AiOutlinePlusCircle } from 'react-icons/ai';

const UniteGestionForm = () => {

    const navigate = useNavigate();
    const {id_unite_gestion} = useParams();
    const {unites_gestions, user, update_unites_gestion, create_unites_gestion, delete_unites_gestion} = usePlateformContext();
    let check_unite_gestion = id_unite_gestion === 'nouvelle';
    
    let unite_gestion = getElementData(id_unite_gestion, unites_gestions);

    let model_data = {
        compte_client: user?.compte_client,
        designation: "",
        devise: "",
        valeur_devise: 0,
        pays: "",
        tutelle: "",
        numero_projet: "",
        numero_pret: "",
        coordonateur: "",
        cpio: "",
        cout_total_devise: 0,
        cout_total: 0,

        financements: []
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.designation) {
                errors.designation = "Entrer la designation";
            }
           

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_unite_gestion ? await update_unites_gestion(credentials) : await create_unites_gestion(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_unite_gestion ? model_data : unite_gestion);
    }, [unites_gestions])


    const ajouter_section_financement = () => {
        let section = credentials.financements
        section.push({auteur : "", montant: 0})

        console.log(section)
        
        setCredentials({...credentials, ["financements"] : section})
    }

    const supprimer_section_financement = (indice) => {
        let section = [...credentials.financements]; // Créez une copie du tableau pour éviter de modifier l'original directement
        if (indice >= 0 && indice < section.length) {
            section.splice(indice, 1); // Supprimez l'élément à l'indice spécifié
            setCredentials({...credentials, ["financements"]: section});
        } else {
            console.error("Indice invalide. L'élément n'a pas été supprimé.");
        }
    }

    const handleFinancementChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFinancement = [...credentials.financements];
        updatedFinancement[index][name] = value;
    
        setCredentials({
            ...credentials,
            financement: updatedFinancement
        });
    };

    let options_devises = [
        {label: "Euros", value: "Euros"},
        {label: "Dollars", value: "Dollars"},
    ]

    let total_financement_devise = 0

    credentials?.financements?.map(item => {
        total_financement_devise = total_financement_devise + (item.montant ? parseFloat(item.montant) : 0)
    })

    let total_financement = total_financement_devise * parseFloat(credentials?.valeur_devise)

    console.log(credentials)

  return (
    <MainContent title={`${check_unite_gestion ? 'Créer une unité de gestion de projet' : unite_gestion?.designation}`}>
        <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>

                <Input
                    type={"text"}
                    label={"Designation"}
                    required={true}
                    placeholder={"Designation"}
                    autoComplete='off'
                    value={credentials?.designation}
                    error={credentialsError.designation}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("designation", null)}
                    name={"designation"}
                />
            </div>

            <div className='mt-4 border-b-[1px] pb-2'>
                <span className='text-sm font-medium'>Données de référence du projet</span>
            </div>
            
            <div>
                <Input
                    type={"text"}
                    label={"Pays"}
                    required={true}
                    placeholder={"Pays"}
                    autoComplete='off'
                    value={credentials?.pays}
                    error={credentialsError.pays}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("pays", null)}
                    name={"pays"}
                />

                <Input
                    type={"text"}
                    label={"Tutelle d projet"}
                    required={true}
                    placeholder={"Tutelle d projet"}
                    autoComplete='off'
                    value={credentials?.tutelle}
                    error={credentialsError.tutelle}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("tutelle", null)}
                    name={"tutelle"}
                />

                <div className='flex items-center md:justify-between md:flex-row flex-col'>
                    <Input
                        type={"text"}
                        label={"Numéro du prêt"}
                        required={true}
                        placeholder={"Numéro du prêt"}
                        autoComplete='off'
                        value={credentials?.numero_pret}
                        error={credentialsError.numero_pret}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("numero_pret", null)}
                        name={"numero_pret"}
                        className={"w-[100%] md:w-[49%]"}
                    />

                    <Input
                        type={"text"}
                        label={"Numéro du Projet"}
                        required={true}
                        placeholder={"Numéro du Projet"}
                        autoComplete='off'
                        value={credentials?.numero_projet}
                        error={credentialsError.numero_projet}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("numero_projet", null)}
                        name={"numero_projet"}
                        className={"w-[100%] md:w-[49%]"}
                    />
                </div>

                <div className='flex items-center md:justify-between md:flex-row flex-col'>
                    <Input
                        type={"text"}
                        label={"CPIO"}
                        required={true}
                        placeholder={"CPIO"}
                        autoComplete='off'
                        value={credentials?.cpio}
                        error={credentialsError.cpio}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("cpio", null)}
                        name={"cpio"}
                        className={"w-[100%] md:w-[49%]"}
                    />

                    <Input
                        type={"text"}
                        label={"Coordonnateur"}
                        required={true}
                        placeholder={"Coordonnateur"}
                        autoComplete='off'
                        value={credentials?.coordonateur}
                        error={credentialsError.coordonateur}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("coordonateur", null)}
                        name={"coordonateur"}
                        className={"w-[100%] md:w-[49%]"}
                    />
                </div>
            </div>
            
            <div className='mt-4 border-t-[1px] pb-2'>
                <span className='text-sm font-medium'>Equivalences monétaires</span>
            </div>

            <div className='flex items-center md:justify-between md:flex-row flex-col'>
                <div className='flex items-center w-[100%] md:w-[49%]'>
                    <Input
                        type={"text"}
                        placeholder={"Montant"}
                        autoComplete='off'
                        value={1}
                        className={"w-[50%]"}
                        disabled={true}
                    />

                    <Select
                        placeholder={"Devise"}
                        autoComplete='off'
                        value={credentials?.devise}
                        error={credentialsError.devise}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("devise", null)}
                        name={"devise"}
                        className={"w-[50%]"}
                        options={options_devises}
                    />
                </div>

                <div className='flex items-center w-[100%] md:w-[49%]'>
                    <Input
                        type={"text"}
                        placeholder={"Valeur en FCFA"}
                        autoComplete='off'
                        value={credentials?.valeur_devise}
                        error={credentialsError.valeur_devise}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("valeur_devise", null)}
                        name={"valeur_devise"}
                        className={"w-[50%]"}
                    />

                    <Input
                        placeholder={"Devise"}
                        autoComplete='off'
                        value={"FCFA"}
                        className={"w-[50%]"}
                        disabled={true}
                    />
                </div>
            </div>

            <div>

                <div className='my-4 border-t-[1px] pt-4 flex justify-start items-center'>
                    <span className='text-sm font-medium'>Financements</span>

                    <a 
                        className={"text-color-plt-1 rounded-md ml-2 text-lg flex items-center cursor-pointer"}
                        onClick={() => ajouter_section_financement()}
                    >
                        <AiOutlinePlusCircle/>
                    </a>
                </div>

                {credentials?.financements?.map((item, index) => {
                    return(
                        <div key={index + 1} className='p-2 border-[1px] border-gray-300 rounded my-2 mb-3'>

                            <div className='flex justify-end mb-2'>
                                <a
                                    onClick={() => supprimer_section_financement(index)}
                                    className='text-color-plt-6 rounded-md text-md flex items-center cursor-pointer'>
                                    <AiOutlineDelete/>
                                </a>
                            </div>

                            <div className='flex items-center md:justify-between md:flex-row flex-col'>
                                <Input
                                    type={"text"}
                                    label={"Financer par"}
                                    required={true}
                                    placeholder={"Financer par"}
                                    autoComplete='off'
                                    error={credentialsError.designation}

                                    value={credentials.financements[index].auteur}
                                    onChange={(e) => handleFinancementChange(e, index)}

                                    onFocus={() => handleChangeError("auteur", null)}
                                    name={"auteur"}
                                    className={"w-[100%] md:w-[49%]"}
                                />

                                <Input
                                    type={"text"}
                                    label={`Montant (${credentials.devise})`}
                                    required={true}
                                    placeholder={`Montant (${credentials.devise})`}
                                    autoComplete='off'
                                    error={credentialsError.designation}

                                    value={credentials.financements[index].montant}
                                    onChange={(e) => handleFinancementChange(e, index)}

                                    onFocus={() => handleChangeError("montant", null)}
                                    name={"montant"}
                                    className={"w-[100%] md:w-[49%]"}
                                />
                            </div>
                        </div>
                    )
                })}


                <div className='flex md:justify-between md:flex-row flex-col md:items-center mt-4'>
                    <Input
                        type={"text"}
                        label={`Coût total du financement en ${credentials?.devise}`}
                        placeholder={"Montant"}
                        autoComplete='off'
                        value={FormatPrice(total_financement_devise)}
                        error={credentialsError.cout_total_devise}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("cout_total_devise", null)}
                        name={"cout_total_devise"}
                        className={"w-[100%] md:w-[49%] mb-3 md:mb-0"}
                        disabled={true}
                    />

                    <Input
                        type={"text"}
                        label={`Coût total du financement en FCFA`}
                        placeholder={"Montant"}
                        autoComplete='off'
                        value={FormatPrice(total_financement)}
                        error={credentialsError.cout_total}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("cout_total", null)}
                        name={"cout_total"}
                        className={"w-[100%] md:w-[49%]"}
                        disabled={true}
                    />
                </div>

                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>

        {!check_unite_gestion && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_unites_gestion(unite_gestion); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <AiOutlineDelete/>
                <span className='ml-1'>Supprimer</span>
                <span className='ml-1 text-dark'>(non recommandé)</span>
            </button>
        </div>}
    </div>
    </MainContent>
  )
}

export default UniteGestionForm