import React from 'react'
import { LinkBtn, MainContent } from '../../../components'
import { Link, useParams } from 'react-router-dom';
import { usePlateformContext } from '../../../contexts';
import { AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai';
import { check_admin, getElementData } from '../../../constances';
import { Fragment } from 'react';

const PlansActionsView = () => {

    const {id_unite_gestion, id_feuille_de_route} = useParams();
    const {feuilleDeRoutes, activitesPlanAction, planActions, user} = usePlateformContext();

    let feuille_de_route = getElementData(id_feuille_de_route, feuilleDeRoutes)
    let activitesPlanAction_data = activitesPlanAction.filter(item => item.feuille_de_route?.toString() === id_feuille_de_route)

    console.log(planActions)

  return (
    <MainContent title={`Plan d'actions : Feuille de route de l’expert en suivi évaluation (${feuille_de_route?.annee})`}>
        {/* Entete */}
        {check_admin(user) && <div className='flex items-center mt-5 justify-end md:flex-row flex-col px-2 md:px-1 py-4'>
            <LinkBtn
                description={"Activité"}
                icon={<AiOutlinePlusCircle/>}
                path={`/unites-gestions/${id_unite_gestion}/plans-actions/feuille-de-route/${id_feuille_de_route}/activite/nouvelle`}
                className={'bg-color-plt-1 text-light w-[100%] md:w-auto mb-2 md:mb-0'}
            />

            <LinkBtn
                description={"Plan d'action"}
                icon={<AiOutlinePlusCircle/>}
                path={`/unites-gestions/${id_unite_gestion}/plans-actions/feuille-de-route/${id_feuille_de_route}/actions/nouvelle`}
                className={'bg-color-plt-1 text-light w-[100%] md:w-auto mb-2 md:mb-0'}
            />
        </div>}

        {/* Tableau des composantes sous-composantes et volets d'activites */}
        <div className='container px-2 p-4'>
            {/* Composantes */}
            <div className='table-container mb-10'>
                <table className='table'>
                    <thead className='bg-dark text-light'>
                        <th>N°</th>
                        <th>Activité</th>
                        <th>Situation précedente</th>
                        <th>Action à mener</th>
                        <th>Partie prenante</th>
                        <th>Periode</th>
                        <th>Etat de mise en oeuvre</th>
                        <th>Commentaire</th>
                    </thead>
                    <tbody>
                        {activitesPlanAction_data.map((item, index) => {

                            let plan_actions_liste = planActions.filter(_item => _item.activite === item.id);

                            return(
                                <Fragment>
                                    <tr>
                                        <td rowSpan={plan_actions_liste.length > 0 ? plan_actions_liste.length + 1 : 2} className='w-[50px]'>{index + 1}</td>
                                        <td rowSpan={plan_actions_liste.length > 0 ? plan_actions_liste.length + 1 : 2} className='whitespace-normal'>
                                            <span className='flex flex-wrap items-center'>
                                            {item.designation} 
                                            {check_admin(user) && <Link
                                                to={`/unites-gestions/${id_unite_gestion}/plans-actions/feuille-de-route/${id_feuille_de_route}/activite/${item.id}`}
                                                className={'text-color-plt-5 ml-1'}
                                            >(Modifier)</Link>}
                                            </span>
                                        </td>
                                        <td rowSpan={plan_actions_liste.length > 0 ? plan_actions_liste.length + 1 : 2} className='whitespace-normal'>{item.situation_precedente}</td>
                                    </tr>

                                    {plan_actions_liste.map((plan_action, index) => {
                                        return(
                                            <tr key={index}>
                                                <td className='whitespace-normal'>{plan_action.action}</td>
                                                <td className='whitespace-normal'>{plan_action.partie_prenante}</td>
                                                <td className='whitespace-normal'>{plan_action.periode}</td>
                                                <td className='whitespace-normal'>{plan_action.etat_de_mise_en_oeuvre}</td>
                                                <td className='whitespace-normal'>{plan_action.commentaire}</td>
                                                {check_admin(user) && <td className='w-[90px]'>
                                                    <span className='flex'>
                                                        <LinkBtn
                                                            description={"Modifier"}
                                                            icon={<AiOutlineEdit/>}
                                                            className={"h-5"}
                                                            path={`/unites-gestions/${id_unite_gestion}/plans-actions/feuille-de-route/${id_feuille_de_route}/actions/${plan_action.id}`}
                                                        />
                                                    </span>
                                                </td>}
                                            </tr>
                                        )
                                    })}

                                    {!plan_actions_liste.length > 0 && <tr>
                                            <td className='text-center'>-</td>
                                            <td className='text-center'>-</td>
                                            <td className='text-center'>-</td>
                                            <td className='text-center'>-</td>
                                            <td className='text-center'>-</td>
                                            <td className='text-center'>-</td>
                                        </tr>}
                                </Fragment>
                            )
                        })}

                        {!activitesPlanAction_data.length > 0 && <tr>
                                <td colSpan={8} className='h-20 text-center'>Aucune activité</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </MainContent>
  )
}

export default PlansActionsView