import React from 'react'
import {LabelValue, MainContent } from '../../../components'
import { usePlateformContext } from '../../../contexts'
import { AiOutlineUser } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const ProfileView = () => {

  const {user} = usePlateformContext();

  return (
    <MainContent title={`Mon profile`}>
      <div className='flex md:items-start md:justify-between mt-5 md:flex-row flex-col px-2 md:px-1 py-4'>
        <div className='w-[100%] md:w-[25%] flex flex-col items-center bg-white rounded-md py-3 justify-center mb-5'>
          <div className='bg-color-plt-2 w-[120px] h-[120px] rounded-full flex justify-center items-center'>
            <strong className='text-[50px] text-light font-normal'>{user.avatar}</strong>
          </div>

          <div className='flex items-center p-2 mt-2 justify-center'>
            <AiOutlineUser className='text-sm'/>
            <strong className='ml-2 text-sm'>{user.user_id}</strong>
          </div>

        </div>

        <div className='w-[100%] md:w-[74%] flex flex-col bg-white rounded-md py-3 px-2 justify-center mb-5'>
          <LabelValue
              label={"Numéro de compte"}
              value={"N° " + user?.numero_client}
              // no_border={true}
            />

            <LabelValue
              label={"Profil"}
              value={
                <div className='flex'>
                  <span className='capitalize flex px-2 rounded-md bg-color-plt-5 text-light'>{user?.profile}</span>
                </div>
              }
              // no_border={true}
            />   

            <LabelValue
              label={"Nom(s)"}
              value={user?.nom}
              // no_border={true}
            />

            <LabelValue
              label={"Prénom(s)"}
              value={user?.prenom}
              // no_border={true}
            />

            <LabelValue
              label={"Genre"}
              value={user?.genre}
              // no_border={true}
            />

            <LabelValue
              label={"Téléphone"}
              value={user?.phone}
              // no_border={true}
            />

            <LabelValue
              label={"E-mail"}
              value={user?.email}
              // no_border={true}
            />    

            <div className='flex justify-end mt-3'>
              <Link to={`/change-password`} className='text-color-plt-5 font-medium text-sm'>Modifier le mot de passe<sup>*</sup></Link>
            </div>

                    
        </div>
      </div>
    </MainContent>
  )
}

export default ProfileView