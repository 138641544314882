import React from 'react'
import { MainContent } from '../../../components'
import { usePlateformContext } from '../../../contexts';
import { FormatDate, check_admin } from '../../../constances';

const AbonnementsView = () => {

    const {abonnement_list, user} = usePlateformContext();

  return (
    <MainContent title={`Abonnements`}>
        {/* Entete */}
        {check_admin(user) && <div className='flex items-center mt-5 justify-end md:flex-row flex-col px-2 md:px-1 py-4'>
            {/* <LinkBtn
                description={"Indicateur"}
                icon={<AiOutlinePlusCircle/>}
                path={`/unites-gestions/${id_unite_gestion}/indicateurs/nouvelle`}
                className={'bg-color-plt-1 text-light w-[100%] md:w-auto mb-2 md:mb-0'}
            /> */}
        </div>}

        {/* Tableau des composantes sous-composantes et volets d'activites */}
        <div className='container px-2 p-4'>
            {/* Composantes */}
            <div className='table-container mb-10'>
                <table className='table'>
                    <thead className='bg-dark text-light'>
                        <th>N°</th>
                        <th>Type</th>
                        <th>Nombre de jour</th>
                        <th>Date debut</th>
                        <th>Date fin</th>
                        <th>Jour restant</th>
                        <th>Actif</th>

                    </thead>
                    <tbody>
                        {abonnement_list.map((item, index) => {
                            return(
                                <tr>
                                    <td className='w-[50px]'>{index + 1}</td>
                                    <td className='whitespace-normal capitalize'>{item.type_abonnement}</td>
                                    <td>{item.nombre_jour}</td>
                                    <td>{FormatDate(item.date_debut)}</td>
                                    <td>{FormatDate(item.date_fin)}</td>
                                    <td>{item.jour_restant}</td>
                                    <td>
                                        {item.jour_restant > 0 ? <span className='bg-color-plt-2 block w-[13px] h-[13px] rounded-[50%] mx-auto'></span>: <span className='bg-color-plt-6 block w-[13px] h-[13px] rounded-[50%] mx-auto'></span>}
                                    </td>
                                </tr>
                            )
                        })}

                        {!abonnement_list.length > 0 && <tr>
                                <td colSpan={7} className='h-20 text-center'>Aucun abonnement</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </MainContent>
  )
}

export default AbonnementsView