import React, { Fragment, useEffect, useState } from 'react'
import { EvolutionTimeLine, MainContent, Select } from '../../../components'
import { Link, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { FormatPrice, getElementData } from '../../../constances';

import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

const ReportingView = () => {

    const {id_unite_gestion} = useParams();
    const {unites_gestions, activites, ptba, categorieDepenses, composantes, sousComposantes, sousActivites} = usePlateformContext();

    let unite_gestion = getElementData(id_unite_gestion, unites_gestions);

    let composantes_data = composantes.filter(item => item.unitegestion.toString() === id_unite_gestion);
    let ptba_data = ptba.filter(item => item.unite_gestion.toString() === id_unite_gestion);
    let categorie_depenses_data = categorieDepenses.filter(item => item.unite_gestion.toString() === id_unite_gestion);
    let sous_composante_data = sousComposantes.filter(item => item.unitegestion.toString() === id_unite_gestion);
    
    let activites_data = activites.filter(item => item.unitegestion.toString() === id_unite_gestion);
    let sous_activites_data = sousActivites.filter(item => item.unite_gestion.toString() === id_unite_gestion);

    // Filtre options
    let model_data = {
        ptba: "",
        categorie_depenses: "",
        composante: "",
        nature: ""
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const [optionsPTBA, setOptionsPTBA] = useState([]);
    const [optionsCategorie, setOptionsCategorie] = useState([]);
    const [optionsComposante, setOptionsComposante] = useState([]);

    useEffect(() => {
        let options = []

        ptba_data.map(item => {
            options.push({value: item.id, label: `PTBA - ${item.annee}`})
        })

        setOptionsPTBA(options);
    }, [ptba])

    useEffect(() => {
        let options = []

        categorie_depenses_data.map(item => {
            options.push({value: item.id, label: `${item.designation}`})
        })

        setOptionsCategorie(options);
    }, [categorieDepenses])

    useEffect(() => {
        let options = []

        composantes_data.map(item => {
            options.push({value: item.id, label: `${item.designation}`})
        })

        setOptionsComposante(options);
    }, [composantes])

    // Filtre activites
    function filterActivites(activites_data, credentials) {
        let data = credentials.ptba ? ptba_data.filter(item => item.id.toString() === credentials.ptba.toString())[0]?.sous_activites : activites_data

        return data.filter(item => 
            (credentials.nature ? item.nature === credentials.nature : true) &&
            (credentials.categorie_depenses ? item.categorie_depense.toString() === credentials.categorie_depenses : true) &&
            (credentials.composante ? item.composante.toString() === credentials.composante : true)
        )
        
    }

    // Data de la chart
    // const [data_realisation, setDataRealisation] = useState({
    //     labels: [],
    //     datasets: [
    //       {
    //         label: "Realisation sur 100%",
    //         backgroundColor: ["#0D7D6F"],
    //         borderColor: ["#0D7D6F"],
    //         borderWidth: 0,
    //         data: []
    //       }
    //     ]
    // });

    // const [data_budget, setDataBudget] = useState({
    //     labels: [],
    //     datasets: [
    //       {
    //         label: "Realisation sur 100%",
    //         backgroundColor: ["#0D7D6F"],
    //         borderColor: ["#0D7D6F"],
    //         borderWidth: 0,
    //         data: []
    //       }
    //     ]
    // });

    let filteredActivites = filterActivites(sous_activites_data, credentials);

    let data_realisation = {
        labels: filteredActivites.map((item, index) => `${item.get_reference}`),
        datasets: [
          {
            label: "Réalisation sur 100%",
            backgroundColor: ["#0D7D6F", "#264653"],
            borderColor: ["#0D7D6F", "#264653"],
            borderWidth: 0,
            data: filteredActivites.map(item => item.pourcentage)
          }
        ]
    };

    let data_budget = {
        labels: filteredActivites.map((item, index) => `${item.get_reference}`),
        datasets: [
          {
            label: "Montant total",
            backgroundColor: ["#F4A261", "#E76F51"],
            borderColor: ["#F4A261", "#E76F51"],
            borderWidth: 0,
            data: filteredActivites.map(item => item.montant)
          }
        ]
    };

    let somme_activites_devise_locale = 0;
    let somme_activites_devise_bayeur = 0;

    filteredActivites.map(item => {
        somme_activites_devise_locale = somme_activites_devise_locale + item.montant;
        somme_activites_devise_bayeur = somme_activites_devise_bayeur + (item.montant / unite_gestion?.valeur_devise);
    })

    let total_categorie = 0
    let total_categorie_devise = 0

    if (credentials.categorie_depenses) {
        let categorie = getElementData(credentials?.categorie_depenses, categorie_depenses_data)

        total_categorie = parseInt(categorie?.montant_devise_locale)
        total_categorie_devise = parseFloat(categorie?.montant_devise).toFixed(2)
    } else {
        categorie_depenses_data.map(item => {
            total_categorie = total_categorie + parseInt(item.montant_devise_locale)
            total_categorie_devise = total_categorie_devise + parseInt(parseFloat(item.montant_devise).toFixed(0))
        })
    }

    let optionsNature = [
        {value: 'Investissement', label: 'Investissement'},
        {value: 'Fonctionnement', label: 'Fonctionnement'}
    ]

    console.log(credentials)
    console.log(filteredActivites)
    
    

  return (
    <MainContent title={`Rapport d'activités`}>
        <div className={`flex flex-col justify-center items-center`}>
            <div className='container flex flex-col flex-grow'>
                <div className='flex items-center mt-5 w-full md:flex-row flex-col py-4'>
                    <Select
                        label={"Programme de Travail et Budget Annuel (PTBA)"}
                        placeholder={"Tous les PTBA"}
                        autoComplete='off'
                        value={credentials?.ptba}
                        error={credentialsError.ptba}
                        onChange={handleChange}
                        name={"ptba"}
                        className={"md:w-[25%] w-[100%] mb-2"}
                        options={optionsPTBA}
                    />

                    <Select
                        label={"Nature d'activité"}
                        placeholder={"Toutes les natures"}
                        autoComplete='off'
                        value={credentials?.nature}
                        error={credentialsError.nature}
                        onChange={handleChange}
                        name={"nature"}
                        className={"md:w-[25%] w-[100%] mb-2"}
                        options={optionsNature}
                    />

                    <Select
                        label={"Categorie de depenses"}
                        placeholder={"Toutes les categories"}
                        autoComplete='off'
                        value={credentials?.categorie_depenses}
                        error={credentialsError.categorie_depenses}
                        onChange={handleChange}
                        name={"categorie_depenses"}
                        className={"md:w-[25%] w-[100%] mb-2"}
                        options={optionsCategorie}
                    />

                    <Select
                        label={"Composante"}
                        placeholder={"Toutes les composantes"}
                        autoComplete='off'
                        value={credentials?.composante}
                        error={credentialsError.composante}
                        onChange={handleChange}
                        name={"composante"}
                        className={"md:w-[25%] w-[100%] mb-2"}
                        options={optionsComposante}
                    />
                </div>

                <div className='flex flex-col md:flex-row w-full md:items-start md:justify-between'>
                    <div className='flex-grow w-[100%] md:w-[75%] mb-4 flex flex-col md:flex-row md:justify-between items-center'>
                        <div className='md:w-[49.5%] mb-4 md:mb-0 w-[100%] md:h-[250px] border-[1px] p-2 border-gray-400 rounded-md'>
                            <Bar data={data_budget} options={{}} style={{ width: '100%', height: '100%' }} />
                        </div>

                        <div className='md:w-[49.5%] w-[100%] mb-0 md:h-[250px] border-[1px] p-2 border-gray-400 rounded-md'>
                            <Bar data={data_realisation} options={{}} style={{ width: '100%', height: '100%' }} />
                        </div>
                    </div>

                    <div className='flex-grow w-[100%] md:pl-3 md:w-[25%] mb-4'>
                        <div className='table-container'>
                            <table className='table'>
                                <thead className='bg-gray-600 text-light'>
                                    <tr>
                                        <th colSpan={2} className='text-center'>Budget du projet</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td className='text-center w-[50%]'>{FormatPrice(unite_gestion?.cout_total)} FCFA</td>
                                        <td className='text-center w-[50%]'>{FormatPrice(parseFloat(unite_gestion?.cout_total_devise).toFixed(0))} {unite_gestion?.devise}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className='table-container'>
                            <table className='table my-3'>
                                <thead className='bg-gray-600 text-light'>
                                    <tr>
                                        <th colSpan={2} className='text-center'>Budget categorie de depenses</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td className='text-center w-[50%]'>{FormatPrice(total_categorie)} FCFA</td>
                                        <td className='text-center w-[50%]'>{FormatPrice(parseFloat(total_categorie_devise).toFixed(0))} {unite_gestion?.devise}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div className='table-container'>
                            <table className='table'>
                                <thead className='bg-gray-600 text-light'>
                                    <tr>
                                        <th colSpan={2} className='text-center'>Total des activités</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td className='text-center w-[50%]'>{FormatPrice(somme_activites_devise_locale)} FCFA</td>
                                        <td className='text-center w-[50%]'>{FormatPrice(parseFloat(somme_activites_devise_bayeur).toFixed(0))} {unite_gestion?.devise}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='table-container mb-5'>
                    <table className='table'>
                        <thead className='bg-gray-600 text-light'>
                            <tr>
                                <th>Composante</th>
                                <th>Sous-composante</th>
                                <th>N°</th>
                                <th>Actitivté</th>
                                <th>Montant {"FCFA"}</th>
                                <th>Montant {unite_gestion?.devise}</th>
                                <th>Réalisation</th>
                            </tr>
                        </thead>

                        <tbody>
                            {composantes_data.map((composante_item, composante_index) => {

                                let sous_composantes = sous_composante_data.filter(item => item.composante === composante_item.id)

                                if (sous_composantes.length > 0) {
                                    return(
                                        sous_composantes.map((sous_composante_item, index_sous_composante) => {

                                            let activites_composante = filteredActivites.filter(item => item.composante === composante_item.id && item.sous_composante === sous_composante_item.id)


                                            if (activites_composante.length > 0) {

                                                return(
                                                    <Fragment>
                                                        <tr>
                                                            <td rowSpan={activites_composante.length > 0 ? activites_composante.length + 1 : 1} className='whitespace-normal'>{composante_item.designation}</td>
                                                            <td rowSpan={activites_composante.length > 0 ? activites_composante.length + 1 : 1} className='whitespace-normal'>{sous_composante_item.designation}</td>
                                                        </tr>
                
                                                        {activites_composante.map((activite, index) => {

                                                            return(
                                                                <tr key={index}>
                                                                    <td className='text-center font-bold'>{activite.get_reference}</td>
                                                                    <td className='whitespace-normal font-medium'>
                                                                        {activite.designation}
                                                                        <Link
                                                                            to={`/unites-gestions/${id_unite_gestion}/sous-activites/details/${activite.id}`}  
                                                                            className='text-color-plt-5 ml-2'> (Details)</Link>
                                                                    </td>
                                                                    <td className='text-center'>{FormatPrice(activite.montant)}</td>
                                                                    <td className='text-center'>{FormatPrice(parseFloat(activite.montant/unite_gestion?.valeur_devise).toFixed(0))}</td>
                                                                    <td className='text-center'>
                                                                        <EvolutionTimeLine value={activite.pourcentage}/>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </Fragment>
                                                )
                                            } else {
                                                return null
                                            }
                                        })
                                    )
                                } else {
                                    return null
                                }
                                
                            })}

                            {!filteredActivites.length > 0 && <tr>
                                <td colSpan={7} className='h-20 text-center'>Aucune activité</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
    </MainContent>
  )
}

export default ReportingView