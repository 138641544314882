import React from 'react'
import { IMAGES } from '../../constances';

const IsLoading = ({visible}) => {
    if (visible) {
        // return (
        //     <div className='fixed select-none z-[100] md:w-[300px] w-full max-w-[91%] h-[80px] bottom-5 left-[4.5%] md:left-5 px-5 flex justify-between items-center bg-color-plt-1 rounded-md'>
        //         <span className='font-bold text-sm text-light'>En cours de chargement ...</span>
        //         <img src={IMAGES.loading} alt='loading ssp' className='w-[80px] object-contain'/>
        //     </div>
        // )

        return(
            <div className='flex h-screen w-screen fixed bg-body z-[100] justify-center items-center'>
                <div className='flex items-center flex-col justify-center'>
                    <img src={IMAGES.loading} alt='loading ssp' className='w-[100px] object-contain'/>
                    <span className='font-medium absolute bottom-[20px] left-[20px] text-[13px] mt-5 text-color-plt-1'>En cours de chargement ...</span>
                </div>
            </div>
        )
    }else{
        return null;
    }
}

export default IsLoading