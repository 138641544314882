import React, { Fragment } from 'react'
import { LinkBtn, MainContent } from '../../../components'
import { useParams } from 'react-router-dom'
import { AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai'
import { usePlateformContext } from '../../../contexts'
import { check_admin, getElementData } from '../../../constances'

const ActiviteView = () => {

    const {id_unite_gestion} = useParams();
    const {composantes, sousComposantes, voletsActivites, user, activites} = usePlateformContext();

    let activites_data = activites.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let composantes_data = composantes.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let sousComposantes_data = sousComposantes.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let voletsActivites_data = voletsActivites.filter(item => item.unitegestion.toString() === id_unite_gestion)

    console.log(activites)

  return (
    <MainContent title={`Retour`}>
        {/* Entete */}
        <div className='flex items-center mt-5 justify-between px-2 md:px-1 py-4'>

            <div className='xl:max-w-[70%] max-w-[50%]'>
                <h1 className='font-bold text-lg'>Activités</h1>
                <p className='text-md font-light'>Liste des activités enregistrées pour le projet</p>
            </div>

            <div className='flex items-center'>
                {check_admin(user) && <LinkBtn
                    description={"Nouvelle activité"}
                    icon={<AiOutlinePlusCircle/>}
                    path={`/unites-gestions/${id_unite_gestion}/activites/nouvelle`}
                    className={'bg-color-plt-1 text-light md:w-auto w-[100%] mb-2 md:mb-0'}
                />}
            </div>
        </div>

        {/* Tableau des composantes sous-composantes et volets d'activites */}
        <div className='container px-2 p-4'>
            {/* Composantes */}
            <div className='table-container mb-10'>
                <table className='table'>
                    <thead className='bg-dark text-light'>
                        <tr>
                            <th>N°</th>
                            <th>Designation</th>
                            <th>Composante</th>
                            <th>Souus-composante</th>
                            <th>Volet d'activité</th>
                            {check_admin(user) &&<th></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {activites_data.map((item, index) => {
                            return(
                                <tr index={index}>
                                    <td>{index + 1}</td>
                                    <td className="whitespace-normal">{item.designation}</td>
                                    <td className="whitespace-normal">{getElementData(item.composante, composantes_data)?.reference}</td>
                                    <td className="whitespace-normal">{getElementData(item.sous_composante, sousComposantes_data)?.reference}</td>
                                    <td className="whitespace-normal">{getElementData(item.volet_activite, voletsActivites_data)?.designation}</td>

                                    {check_admin(user) && <td className='w-[100px]'>
                                        <span className='flex'>
                                            <LinkBtn
                                                description={"Modifier"}
                                                icon={<AiOutlineEdit/>}
                                                className={"h-5"}
                                                path={`/unites-gestions/${id_unite_gestion}/activites/${item.id}`}
                                            />
                                        </span>
                                    </td>}
                                </tr>
                            )
                        })}

                        {!activites_data.length > 0 && <tr>
                                <td colSpan={6} className='h-20'>Aucune activité</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </MainContent>
  )
}

export default ActiviteView