import React from 'react'

const AuthMain = ({children}) => {

  return (
    <div className='h-[100vh] w-full bg-body flex items-center justify-center'>
        
        <div className='flex bg-white rounded-lg overflow-hidden h-[400px]'>
          <div className='md:flex hidden'>
            <div className='w-full bg-[var(--rgba-black)] bg-color-plt-1 h-full flex flex-col justify-end items-start p-5 max-w-[500px]'>
              <div className='bg-white px-1 rounded-lg'>
                <h1 className='text-color-plt-1 font-bold text-lg'>SSPP</h1>
              </div>
              <p className='text-white font-normal text-md'>Système de Suivi de Projets et Programmes.</p>
              <small className='text-white font-light text-sm'>Une plateforme conçue dans le but de permettre un suivi et une traçabilité efficaces de vos projets.</small>
            </div>
          </div>

          <div className='flex items-center justify-center'>
            {children}
          </div>
        </div>

    </div>
  )
}

export default AuthMain