import React, { useEffect, useState } from 'react'
import { AiFillCalendar, AiFillEdit, AiOutlineCalendar, AiOutlineClockCircle, AiOutlineCloud, AiOutlinePlus } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { usePlateformContext } from '../../../contexts';
import { AbonnementAlert, HomeMain, MainContent, ModalUniteGestion, NavigationTop, Navigations, WeatherIcon } from '../../../components';
import { IMAGES, check_admin } from '../../../constances';

const HomeView = () => {

    const navigate = useNavigate();

    const {weatherData, userCity, userCountry, unites_gestions, user, abonnement_last} = usePlateformContext();

    const [formattedDate, setFormattedDate] = useState(null);
    const [formattedTime, setFormattedTime] = useState(null);

    const [visibleModal, setVisibleModal] = useState(false);
    const [itemSelected, setItemSelected] = useState(null);
 
    let temperatureKelvin = weatherData?.main?.temp;
    let temperatureCelsius = (temperatureKelvin ? temperatureKelvin : 0).toFixed(0);

    useEffect(() => {

        setInterval(() => {
            const now = new Date();
            setFormattedDate(format(now, 'EEEE dd MMMM yyyy', { locale: fr }));
            setFormattedTime(now.toLocaleTimeString());
        }, 1000);
    
    });

    const hundleVisibleModal = (item=null) => {
        setItemSelected(item);
        setVisibleModal(true);
    }

  return (
    <MainContent title={`Accueil`}>
        {/* Content Home Page */}
        <div className={`flex flex-col justify-center items-center`}>

            <AbonnementAlert value={abonnement_last}/>

            <div className='container flex flex-col md:flex-row items-start justify-between flex-grow'>
                <div className='flex-grow px-2 w-[100%] md:w-[50%] mb-4'>
                    {/* Design section de bienvenue */}
                    <div className='p-5 mb-3 border bg-color-plt-1 text-light rounded-md h-[250px] flex flex-col md:flex-row justify-center md:justify-between items-center'>
                        <div className='w-[100%] md:max-w-[50%]'>
                            <h1 className='text-[25px] md:text-[30px] font-medium my-0'>Bienvenue,</h1>
                            <h1 className='text-[30px] md:text-[35px] font-bold my-0'>{user.prenom}</h1>
                            <p>Système de Suivi de Projets et Programmes (SSPP)</p>
                        </div>

                        <div className='w-[400px] py-2 hidden md:block'>
                            <img src={IMAGES.home_design} alt='Home Design' className='w-[100%] h-[230px] my-2 object-contain'/>
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row items-start justify-between'>
                        {/* Section de date et heure */}
                        <div className='w-[100%] bg-color-plt-2 md:w-[49%] text-light flex flex-row items-center justify-between mb-2 border p-2 rounded-md'>
                            <AiOutlineCalendar className='text-[70px]'/>
                            <div className='flex flex-col items-end justify-between w-[100%] mb-2'>
                                <h2 className='text-[30px] font-bold'>{formattedTime ? formattedTime : '00:00:00'}</h2>
                                <span className='text-[15px] capitalize'>{formattedDate ? formattedDate : 'Mardi 29 Aout 2000'}</span>
                            </div>
                        </div>

                        {/* Section Meteo */}
                        <div className='w-[100%] bg-color-plt-5 md:w-[49%] text-light flex flex-row items-center justify-between mb-2 border p-2 rounded-md'>
                            {/* <AiOutlineCloud className='text-[70px]'/> */}
                            <WeatherIcon icon={weatherData?.weather ? weatherData?.weather[0]?.icon : '03d'}/>
                            <div className='flex flex-col items-end justify-between w-[100%] mb-2'>
                                <h2 className='text-[30px] font-bold'>{temperatureCelsius}°C</h2>
                                <span className='text-[15px] capitalize'>{weatherData?.weather ? weatherData?.weather[0]?.description : "Température"}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-[100%] md:w-[50%] px-2'>
                    <div className='mb-3'>
                        {(abonnement_last.access && abonnement_last.creation_unite && check_admin(user)) && <button onClick={() => navigate(`/unites-gestion-form/nouvelle`)} className='flex w-full flex-col items-center justify-center rounded-md border-[1px] border-[#aaa] py-2 mb-8'>
                            <AiOutlinePlus className='text-[25px] mb-1 text-dark'/>
                            <span className='text-[13px] font-medium text-dark'>Créer une unité de gestion de projet</span>
                        </button>}
                        
                        {/* Structures deja creer */}
                        <div className='table-container mb-8'>
                            <table className='table'>
                                <thead className='bg-color-plt-1 text-light'>
                                    <td colSpan={check_admin(user) ? 3 : 2}>Unités de gestions de projets</td>
                                </thead>
                                <tbody>
                                    {unites_gestions.map((item, index) => {
                                        return(
                                            <tr key={index}>
                                                <td className='text-center'>{index + 1}</td>
                                                <td className='whitespace-normal'>
                                                    <Link 
                                                        to={abonnement_last.access && `/unites-gestions/${item.id}`} 
                                                        className={`font-bold ${!abonnement_last.access && 'opacity-[0.5]'}`}>{item.designation}</Link>
                                                </td>
                                                {check_admin(user) && <td className='text-center'>
                                                    <button 
                                                        onClick={() => navigate(`/unites-gestion-form/${item.id}`)}>
                                                        <AiFillEdit/>
                                                    </button>
                                                </td>}
                                            </tr>
                                        )
                                    })}

                                    {!unites_gestions.length > 0 && <tr>
                                            <td colSpan={3} className='h-20 opacity-50 text-center'>Aucune unité de gestion de projet</td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        {/* Modal pour le CRUD de unite de gestion */}
        <ModalUniteGestion 
            visible={visibleModal} 
            item={itemSelected} 
            onClose={() => {
                setItemSelected(null); 
                setVisibleModal(false)
            }}/>
        
    </MainContent>

  )
}

export default HomeView