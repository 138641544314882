import React from 'react'
import { LinkBtn, MainContent } from '../../../components'
import { AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai';
import { usePlateformContext } from '../../../contexts';
import { useParams } from 'react-router-dom';
import { FormatPrice, check_admin } from '../../../constances';

const IndicateursView = () => {

    const {id_unite_gestion} = useParams();
    const {indicateurs, user} = usePlateformContext();

    let indicateurs_data = indicateurs.filter(item => item.unite_gestion.toString() === id_unite_gestion) 

  return (
    <MainContent title={`Indicateurs`}>
        {/* Entete */}
        {check_admin(user) && <div className='flex items-center mt-5 justify-end md:flex-row flex-col px-2 md:px-1 py-4'>
            <LinkBtn
                description={"Indicateur"}
                icon={<AiOutlinePlusCircle/>}
                path={`/unites-gestions/${id_unite_gestion}/indicateurs/nouvelle`}
                className={'bg-color-plt-1 text-light w-[100%] md:w-auto mb-2 md:mb-0'}
            />
        </div>}

        {/* Tableau des composantes sous-composantes et volets d'activites */}
        <div className='container px-2 p-4'>
            {/* Composantes */}
            <div className='table-container mb-10'>
                <table className='table'>
                    <thead className='bg-dark text-light'>
                        <th>N°</th>
                        <th>Indicateur</th>
                        <th>Cible</th>
                        <th>A Mi-parcours</th>
                    </thead>
                    <tbody>
                        {indicateurs_data.map((item, index) => {
                            return(
                                <tr>
                                    <td className='w-[50px]'>{index + 1}</td>
                                    <td className='whitespace-normal'>{item.indicateur}</td>
                                    <td>{FormatPrice(item.cible)}</td>
                                    <td>{FormatPrice(item.a_mi_parcours)}</td>
                                    {check_admin(user) && <td className='w-[90px]'>
                                        <span className='flex'>
                                            <LinkBtn
                                                description={"Modifier"}
                                                icon={<AiOutlineEdit/>}
                                                className={"h-5"}
                                                path={`/unites-gestions/${id_unite_gestion}/indicateurs/${item.id}`}
                                            />
                                        </span>
                                    </td>}
                                </tr>
                            )
                        })}

                        {!indicateurs_data.length > 0 && <tr>
                                <td colSpan={4} className='h-20 text-center'>Aucun indicateur</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </MainContent>
  )
}

export default IndicateursView