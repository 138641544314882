import axios from "axios";

const DEBUG = false;

function getSiteUrl() {
    return window.location.origin;
}

// URL du back en local
const LOCAL_URL = "http://localhost:8000";

// URL du back en production
const PRODUCTION_URL = getSiteUrl();


export const BASE_URL = DEBUG ? LOCAL_URL : PRODUCTION_URL;

export const Axios = axios.create({
    baseURL: BASE_URL
});

export const URL_PLATEFORM = '';

// Configuration de l'API Weather
export const openWeatherApi = {
    key: '28d242f717006cfcd5edce6775a2190b',
    base: 'https://api.openweathermap.org/data/2.5/'
}

export const newsApi = {
    key: '93196bb2c0364a388bb68b72dcae5940',
    base: 'https://restcountries.com/v3.1/'
}