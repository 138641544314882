import React from 'react'
import { Button, Input, MainContent } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { getElementData } from '../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const ComposanteForm = () => {

    const navigate = useNavigate();
    const {id_composante, id_unite_gestion} = useParams();
    const {composantes, user, update_composante, create_composante, delete_composante} = usePlateformContext();
    let check_composante = id_composante === 'nouvelle';
    
    let composante = getElementData(id_composante, composantes);

    let model_data = {
        unitegestion: id_unite_gestion,
        designation: "",
        reference: ""
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.designation) {
                errors.designation = "Entrer la designation";
            }

            if (!credentials.reference) {
                errors.reference = "Entrer la référence";
            }
           

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_composante ? await update_composante(credentials) : await create_composante(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_composante ? model_data : composante);
    }, [composantes])

  return (
    <MainContent title={`${check_composante ? 'Créer une composante' : composante?.reference}`}>
        <div className='flex items-center mt-5 justify-between px-2 md:px-1 py-4'>

            <div className='xl:max-w-[70%] max-w-[50%]'>
                <h1 className='font-bold text-lg'>{check_composante ? 'Nouvelle composante' : 'Modifier la composante'}</h1>
                <p className='text-md font-light'>{check_composante ? 'Enregistrer une nouvelle componente pour le projet' : `Modifier la composante "${composante?.reference}"`}</p>
            </div>

            <div className='flex items-center'></div>
        </div>

        <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>
                <Input
                    type={"text"}
                    label={"Référence"}
                    required={true}
                    placeholder={"Ex: composante 1"}
                    autoComplete='off'
                    value={credentials?.reference}
                    error={credentialsError.reference}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("reference", null)}
                    name={"reference"}
                />

                <Input
                    type={"text"}
                    label={"Designation"}
                    required={true}
                    placeholder={"Designation"}
                    autoComplete='off'
                    value={credentials?.designation}
                    error={credentialsError.designation}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("designation", null)}
                    name={"designation"}
                />

                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>

        {!check_composante && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_composante(composante); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <AiOutlineDelete/>
                <span className='ml-1'>Supprimer</span>
                <span className='ml-1 text-dark'>(non recommandé)</span>
            </button>
        </div>}
    </div>
    </MainContent>
  )
}

export default ComposanteForm