import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { usePlateformContext } from '../../contexts';

function PrivateRoot() {
    const {isAuthenticated, user} = usePlateformContext();

  return isAuthenticated ? (
    (
      // <Outlet />
      user.password_changed ? <Outlet /> : <Navigate to={`/change-password`} />
    )
  ) : (
    <Navigate to={`/login`} />
  )
}

export default PrivateRoot



