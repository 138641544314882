import React from 'react'
import { Button, Input, MainContent } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { getElementData } from '../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const CategorieDepenseForm = () => {

    const navigate = useNavigate();
    const {id_unite_gestion, id_categorie_depenses} = useParams();
    const {categorieDepenses, unites_gestions, update_categorie_depenses, create_categorie_depenses, delete_categorie_depenses} = usePlateformContext();
    let check_categorie_depenses = id_categorie_depenses === 'nouvelle';

    let categorie_depense = getElementData(id_categorie_depenses, categorieDepenses);
    let unite_gestion = getElementData(id_unite_gestion, unites_gestions)


    let model_data = {
        unite_gestion: id_unite_gestion,
        designation: "",
        // montant_alloue_au_titre_du_pret: "",
        montant_devise_locale: "",
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.designation) {
                errors.designation = "Entrer la catégorie";
            }

            // if (!credentials.montant_alloue_au_titre_du_pret) {
            //     errors.montant_alloue_au_titre_du_pret = "Entrer le montant alloué au titre du prêt ";
            // }

            if (!credentials.montant_devise_locale) {
                errors.montant_devise_locale = `Entrer le montant en ${"FCFA"}`;
            }
           

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_categorie_depenses ? await update_categorie_depenses(credentials) : await create_categorie_depenses(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_categorie_depenses ? model_data : categorie_depense);
    }, [unites_gestions])


    console.log("CATEGORIE : ", id_categorie_depenses)

  return (
    <MainContent title={`${check_categorie_depenses ? 'Créer une catégorie de dépense' : categorie_depense?.designation}`}>
        <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>

                <Input
                    type={"text"}
                    label={"Catégorie"}
                    required={true}
                    placeholder={"Catégorie"}
                    autoComplete='off'
                    value={credentials?.designation}
                    error={credentialsError.designation}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("designation", null)}
                    name={"designation"}
                />

                {/* <Input
                    type={"number"}
                    label={`Montant Alloué au titre du prêt (en ${unite_gestion?.devise})`}
                    required={true}
                    placeholder={`Montant Alloué au titre du prêt (en ${unite_gestion?.devise})`}
                    autoComplete='off'
                    value={credentials?.montant_alloue_au_titre_du_pret}
                    error={credentialsError.montant_alloue_au_titre_du_pret}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("montant_alloue_au_titre_du_pret", null)}
                    name={"montant_alloue_au_titre_du_pret"}
                /> */}

                <Input
                    type={"number"}
                    label={`Montant de la categorie en (${'FCFA'})`}
                    required={true}
                    placeholder={`Montant de la categorie en (${'FCFA'})`}
                    autoComplete='off'
                    value={credentials?.montant_devise_locale}
                    error={credentialsError.montant_devise_locale}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("montant_devise_locale", null)}
                    name={"montant_devise_locale"}
                />


                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>

        {!check_categorie_depenses && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_categorie_depenses(categorie_depense); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <AiOutlineDelete/>
                <span className='ml-1'>Supprimer</span>
                <span className='ml-1 text-dark'>(non recommendé)</span>
            </button>
        </div>}
    </div>
    </MainContent>
  )
}

export default CategorieDepenseForm