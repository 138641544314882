export const IMAGES = {
    auth_1: require('../assets/images/1.jpg'),
    auth_2: require('../assets/images/2.jpg'),
    auth_3: require('../assets/images/3.jpg'),
    auth_4: require('../assets/images/4.jpg'),
    auth_5: require('../assets/images/5.jpg'),
    auth_6: require('../assets/images/6.jpg'),
    
    loading_btn: require('../assets/images/loading-btn.gif'),
    loading: require('../assets/images/loading-2.gif'),

    home_design: require('../assets/images/home-design.png'),
    avatar: require('../assets/images/avatar-nav.png'),
    data_not_found: require('../assets/images/search-engine.png'),
}