import React, { useEffect, useState } from 'react'
import { AiFillHome, AiOutlinePoweroff } from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { usePlateformContext } from '../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { IMAGES, URL_PLATEFORM } from '../constances';


const Navigations = ({title = ''}) => {

    const {logout, user} = usePlateformContext();
    const navigate = useNavigate();

    const [formattedDate, setFormattedDate] = useState('Loading ...');
    const [formattedTime, setFormattedTime] = useState('');

    useEffect(() => {

        setInterval(() => {
            const now = new Date();
            setFormattedDate(format(now, 'EEEE dd MMM yyyy', { locale: fr }));
            setFormattedTime(now.toLocaleTimeString());
        }, 1000);
    
    });

    const hundleLogout = async () => {
        await logout();
    }

    const hundleBack = () => {
        navigate(-1)
    }


  return (
    <div className='fixed w-full z-50'>
        <div className='bg-color-plt-1 flex justify-center'>
            <div className='container flex justify-between items-center px-2'>
                <div className='h-[100%]'>
                    <Link to={'/'} className='text-light w-[50px]  hover:bg-color-plt-5 hover:border-color-plt-5 h-[100%] text-xl justify-center items-center text-md font-bold flex capitalize'>
                        <AiFillHome/>
                    </Link>
                </div>

                <div className='flex items-center md:justify-start md:w-auto justify-center  h-[100%]'>
                    <Link to={`/profile`} 
                        className='flex items-center h-[100%]'
                    >
                        <img
                            className='w-[40px] h-[40px] my-1 rounded-[50%] object-cover object-center'
                            src={IMAGES.avatar}
                        />

                        <div className='flex flex-col items-start justify-center ml-2'>
                            <span className='text-sm m-0 text-light'><b>{user?.nom}</b></span>
                            {/* Status ou proofile */}
                            <span className='text-light text-sm'>{user?.prenom}</span>
                        </div>
                    </Link>

                    <button className='ml-5 bg-color-plt-5 px-4 text-light h-[100%] text-sm' onClick={() => hundleLogout()}>
                        <AiOutlinePoweroff/>
                    </button>
                </div>
            </div>
        </div>

        {(title.toLowerCase() !== "accueil") && <div className='w-full flex justify-center bg-body'>
            <div className='container flex items-center py-2 md:mx-5 mx-2'>
                <div className='flex items-center'>
                    
                    <button onClick={() => hundleBack()} className='text-dark flex items-center'>
                        <FaArrowLeft className='text-sm'/>
                        <span className='ml-2 text-[14px] font-medium'>{title}</span>
                    </button>
                </div>
                <div></div>
            </div>
        </div>}
    </div>
  )
}

export default Navigations