import React, { useEffect, useState } from 'react'
import { usePlateformContext } from '../../../contexts'
import { useNavigate } from 'react-router-dom';
import { BtnForm, Input, Button, MainContent } from '../../../components';

const PasswordView = () => {

    const {isAuthenticated, password_edit} = usePlateformContext();
    const navigate = useNavigate();

    let model_data = {
        password_actuel: "",
        password_new: "",
        password_confirm: "" 
    };


    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.password_actuel) {
                errors.password_actuel = "Entrer le mot de passe actuel";
            }

            if (!credentials.password_new) {
                errors.password_new = "Entrer le nouveau mot de passe";
            }else if (credentials.password_new.length < 8) {
                errors.password_new = "Mot de passe faible";
            }else if (credentials.password_new === credentials.password_actuel) {
                errors.password_new = "Le nouveau mot de passe doit etre different de l'actuel";
            }

            if (!credentials.password_confirm) {
                errors.password_confirm = "Confirmer le mot de passe";
            }else if (credentials.password_confirm !== credentials.password_new) {
                errors.password_confirm = "Mot de passe incorrecte";
            }

           

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = await password_edit(credentials);

                if (response) {
                    setCredentials(model_data);
                    navigate('/profile');
                }
            }
        
    }


    useEffect(() => {
        if(!isAuthenticated){
            navigate('/login')
        }
    }, [navigate, isAuthenticated])

  return (
    <MainContent title={"Mot de passe"}>
    <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>

                <Input
                    type={"password"}
                    password = {true}
                    label={"Mot de passe actuel"}
                    required={true}
                    placeholder={"Mot de passe actuel"}
                    autoComplete='off'
                    value={credentials.password_actuel}
                    error={credentialsError.password_actuel}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("password_actuel", null)}
                    name={"password_actuel"}
                />

                <Input
                    type={"password"}
                    password = {true}
                    label={"Nouveau mot de passe"}
                    required={true}
                    placeholder={"Nouveau mot de passe"}
                    autoComplete='off'
                    value={credentials.password_new}
                    error={credentialsError.password_new}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("password_new", null)}
                    name={"password_new"}
                />

                <Input
                    type={"password"}
                    password = {true}
                    label={"Confirmer le mot de passe"}
                    required={true}
                    placeholder={"Confirmer le mot de passe"}
                    autoComplete='off'
                    value={credentials.password_confirm}
                    error={credentialsError.password_confirm}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("password_confirm", null)}
                    name={"password_confirm"}
                />

                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>
    </div>
    </MainContent>
  )
}

export default PasswordView