import React from 'react'
import { Button, Input, MainContent, Select, Textarea } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { getElementData } from '../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const PlanActionForm = () => {

    const navigate = useNavigate();
    const {id_unite_gestion, id_feuille_de_route, id_plan_action} = useParams();
    const {feuilleDeRoutes, planActions, activitesPlanAction,  unites_gestions, update_plan_actions, create_plan_actions, delete_plan_actions} = usePlateformContext();
    
    let check_plan_action = id_plan_action === 'nouvelle';

    let plan_action = getElementData(id_plan_action, planActions);

    let actites_liste = activitesPlanAction.filter(item => item.feuille_de_route?.toString() === id_feuille_de_route)
    const [optionsActivites, setOptionsActivites] = useState([])


    let model_data = {
        activite: "",
        action: "",
        partie_prenante: "",
        periode: "",
        etat_de_mise_en_oeuvre: "",
        commentaire: "",
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.action) {
                errors.action = "Entrer l'action";
            }

            if (!credentials.activite) {
                errors.activite = "Selectionner l'activté";
            }

            if (!credentials.partie_prenante) {
                errors.partie_prenante = "Entrer la partie prenante";
            }


            if (!credentials.periode) {
                errors.periode = "Entrer la periode";
            }


            if (!credentials.etat_de_mise_en_oeuvre) {
                errors.etat_de_mise_en_oeuvre = "Entrer l'etat de mise en oeuvre";
            }


            if (!credentials.commentaire) {
                errors.commentaire = "Entrer le commentaire";
            }


            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_plan_action ? await update_plan_actions(credentials) : await create_plan_actions(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_plan_action ? model_data : plan_action);
    }, [])

    useEffect(() => {
        let options = []; 
        
        actites_liste.map(item => {
            options.push({value: item.id, label: item.designation})
        })

        setOptionsActivites(options);
    }, [activitesPlanAction])

    console.log("ID plan d'action", id_plan_action)

  return (
    <MainContent title={`${check_plan_action ? "Créer un plan d'action" : plan_action?.action.substring(0, 50)}`}>
        <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>

                <Select
                    label={"Activité"}
                    required={true}
                    placeholder={"Activité"}
                    autoComplete='off'
                    value={credentials?.activite}
                    error={credentialsError.activite}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("activite", null)}
                    name={"activite"}
                    options={optionsActivites}
                />

                <Textarea
                    label={"Action à mener"}
                    required={true}
                    placeholder={"Action à mener"}
                    autoComplete='off'
                    value={credentials?.action}
                    error={credentialsError.action}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("action", null)}
                    name={"action"}
                />

                <Textarea
                    label={"Partie prenante"}
                    required={true}
                    placeholder={"Partie prenante"}
                    autoComplete='off'
                    value={credentials?.partie_prenante}
                    error={credentialsError.partie_prenante}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("partie_prenante", null)}
                    name={"partie_prenante"}
                />

                <Textarea
                    label={"Période"}
                    required={true}
                    placeholder={"Période"}
                    autoComplete='off'
                    value={credentials?.periode}
                    error={credentialsError.periode}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("periode", null)}
                    name={"periode"}
                />

                <Textarea
                    label={"Etat de mise en oeuvre"}
                    required={true}
                    placeholder={"Etat de mise en oeuvre"}
                    autoComplete='off'
                    value={credentials?.etat_de_mise_en_oeuvre}
                    error={credentialsError.etat_de_mise_en_oeuvre}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("etat_de_mise_en_oeuvre", null)}
                    name={"etat_de_mise_en_oeuvre"}
                />

                <Textarea
                    label={"Commentaire"}
                    required={true}
                    placeholder={"Commentaire"}
                    autoComplete='off'
                    value={credentials?.commentaire}
                    error={credentialsError.commentaire}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("commentaire", null)}
                    name={"commentaire"}
                />


                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>

        {!check_plan_action && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_plan_actions(plan_action); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <AiOutlineDelete/>
                <span className='ml-1'>Supprimer</span>
                <span className='ml-1 text-dark'>(non recommandé)</span>
            </button>
        </div>}
    </div>
    </MainContent>
  )
}

export default PlanActionForm