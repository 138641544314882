import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Select = ({
  className, 
  error, 
  label, 
  name, 
  placeholder, 
  required,
  value,
  onChange,
  onFocus = () => {},
  disabled=false,
  password,
  options = []
}) => {

  const [visiblePassword, setVisiblePassword] = useState(false);

  return (
    <div className={`flex flex-col ${className} p-0`}>
        <label className='text-xs mb-1'>
          {label}
          {required && <span className="text-red-500 text-lg">*</span>}
        </label>

        <div className={`flex items-center border ${error ? 'border-red-500' : 'border-gray-300'}`}>
            <select
              name={name}
              className='flex-grow outline-none border-0 px-2 font-normal h-10 max-w-[100%]'
              value={value}
              onChange={onChange}
              onFocus={onFocus}
              disabled={disabled}
            >
              {placeholder && <option value={""}>{placeholder}</option>}
              {options.map((item, index) => {
                return(
                  <option className="truncate" key={index} value={item.value}>{item.label}</option>
                )
              })}
            </select>

            {password && (
              <span className='flex items-center justify-center h-10 w-12 bg-gray-200 text-black' onClick={() => setVisiblePassword(!visiblePassword)}>
                {visiblePassword ? <FaEyeSlash className="text-gray-400" /> : <FaEye className="text-gray-400" />}
              </span>
            )}
        </div>

        {error && <span className='text-xs text-red-500'>{error}</span>}
    </div>
  )
}

export default Select;
