import React from 'react'
import { Button, Input, MainContent, Select } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { getElementData } from '../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiFillCheckSquare, AiOutlineDelete } from 'react-icons/ai';
import { FiRefreshCcw } from "react-icons/fi";

const UtilisateurForm = () => {

    const navigate = useNavigate();
    const {id_unite_gestion, id_utilisateur} = useParams();
    const {get_utilisateurs, token, utilisateurs, user, composantes, unites_gestions, update_utilisateurs, create_utilisateurs, delete_utilisateurs} = usePlateformContext();
    let check_id_utilisateur = id_utilisateur === 'nouvelle';
    
    let utilisateur = getElementData(id_utilisateur, utilisateurs);
    let composantes_data = composantes.filter(item => item.unitegestion.toString() === id_unite_gestion)

    let model_data = {
        compte_client: user?.compte_client,
        unite_gestion: id_unite_gestion,

        composantes: [],
        profile: "",
        nom: "",
        prenom: "",
        genre: "",
        phone: "",
        email: "",
        active: true
    };


    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (credentials.profile === "responsable" && credentials.composantes?.length === 0) {
                errors.composantes = "Selectionner au moins une composante";
            }

            if (!credentials.profile) {
                errors.profile = "Selectionner le profil";
            }

            if (!credentials.nom) {
                errors.nom = "Entrer le nom";
            }

            if (!credentials.prenom) {
                errors.prenom = "Entrer le prenom";
            }

            if (!credentials.genre) {
                errors.genre = "Selectionner le genre";
            }

            if (!credentials.phone) {
                errors.phone = "Entrer le téléphone";
            }

            if (!credentials.email) {
                errors.email = "Entrer l'e-mail";
            }
           

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_id_utilisateur ? await update_utilisateurs(credentials) : await create_utilisateurs(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_id_utilisateur ? model_data : utilisateur);
    }, [id_utilisateur, utilisateurs])

    let options_profile = [
        {value: "responsable", label: "Responsable"},
        {value: "coordonateur", label: "Coordonateur"},
    ]

    let options_genre = [
        {value: "M", label: "Homme"},
        {value: "F", label: "Femme"},
    ]

    let options_actif = [
        {value: true, label: "Oui"},
        {value: false, label: "Non"},
    ]

    const check_indicateur = (id) => {
        return credentials?.composantes?.includes(id)
    }

    const onClickIndicateur = (id) => {
        handleChangeError("composantes", null)

        let list_composantes = credentials?.composantes

        const index = list_composantes.indexOf(id);

        if (index !== -1) {
            list_composantes.splice(index, 1);
        } else {
            list_composantes.push(id)
        }

        setCredentials({...credentials, composantes: list_composantes})
    }
    

    const refresh = async () => {
        await get_utilisateurs(token);
    };
    
    useEffect(() => {
        refresh();
    }, [id_utilisateur]);
    
    
  return (
    <MainContent title={`${check_id_utilisateur ? 'Créer un responsable projet' : `Responsable : ${utilisateur?.nom} ${utilisateur?.prenom}`.substring(0, 70) + '...'}`}>
        <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>

                <Select
                    label={"Profil"}
                    required={true}
                    placeholder={"Selectionner le profil"}
                    autoComplete='off'
                    value={credentials?.profile}
                    error={credentialsError.profile}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("profile", null)}
                    name={"profile"}
                    options={options_profile}
                />

                <Input
                    type={"text"}
                    label={"Nom(s)"}
                    required={true}
                    placeholder={"Nom(s)"}
                    autoComplete='off'
                    value={credentials?.nom}
                    error={credentialsError.nom}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("nom", null)}
                    name={"nom"}
                />

                <Input
                    type={"text"}
                    label={"Prénom(s)"}
                    required={true}
                    placeholder={"Prénom(s)"}
                    autoComplete='off'
                    value={credentials?.prenom}
                    error={credentialsError.prenom}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("prenom", null)}
                    name={"prenom"}
                />

                <Select
                    label={"Genre"}
                    required={true}
                    placeholder={"Selectionner le genre"}
                    autoComplete='off'
                    value={credentials?.genre}
                    error={credentialsError.genre}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("genre", null)}
                    name={"genre"}
                    options={options_genre}
                />

                <Input
                    type={"text"}
                    label={"Téléphone"}
                    required={true}
                    placeholder={"Téléphone"}
                    autoComplete='off'
                    value={credentials?.phone}
                    error={credentialsError.phone}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("phone", null)}
                    name={"phone"}
                />

                <Input
                    type={"text"}
                    label={"E-mail"}
                    required={true}
                    placeholder={"E-mail"}
                    autoComplete='off'
                    value={credentials?.email}
                    error={credentialsError.email}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("email", null)}
                    name={"email"}
                />

                <Select
                    label={"Actif"}
                    required={true}
                    autoComplete='off'
                    value={credentials?.active}
                    error={credentialsError.active}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("active", null)}
                    name={"active"}
                    options={options_actif}
                />

                {credentials?.profile === "responsable" && <div className='table-container mt-5'>
                    <table className='table'>
                        <tbody>
                            {composantes_data.map((item, index) => {
                                return(
                                    <tr key={index}>
                                        <td className='w-[50px] text-center'>
                                            <a onClick={() => onClickIndicateur(item.id)} className='w-full cursor-pointer flex justify-center'>
                                                {check_indicateur(item.id) ? <AiFillCheckSquare className='text-[20px] text-color-plt-2'/> : <span className='flex w-[15px] h-[15px] border-[1px] border-color-plt-2'></span>}
                                            </a>
                                        </td>
                                        <td>Comp. {item.reference}</td>
                                        <td className='whitespace-normal'>{item.designation}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>}

                {credentialsError.composantes && <span className='text-color-plt-6 text-sm mt-2'>{credentialsError.composantes}</span>}

                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>

        {!check_id_utilisateur && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_utilisateurs(utilisateur); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <FiRefreshCcw/>
                <span className='ml-1'>Réinitialiser le mot de passe</span>
            </button>

            <p className='text-[12px] text-dark mt-1'>Cela rétablira le mot de passe du responsable à sa valeur par défaut.</p>
        </div>}
    </div>
    </MainContent>
  )
}

export default UtilisateurForm