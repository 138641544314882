import React from 'react'
import { Button, Input, MainContent, Select, Textarea } from '../../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../../contexts';
import { getElementData } from '../../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const ActiviteRessourceBudgetForm = () => {

    const navigate = useNavigate();
    const {id_unite_gestion, id_activite, id_ressource} = useParams();
    const {taches, activites, ressourcesBudgets, update_ressources_budgets, create_ressources_budgets, delete_ressources_budgets} = usePlateformContext();
    
    let check_ressource = id_ressource === 'nouvelle';
    let ressource_selected = getElementData(id_ressource, ressourcesBudgets);

    const [optionsTache, setOptionTache] = useState([]);

    let taches_data = taches.filter(item => item.activite.toString() === id_activite)


    let model_data = {
        tache: "",
        unite: "",
        quantite: 0,
        prix_unitaire: 0,
        montant: 0,
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.tache) {
                errors.tache = "Selectionner la tache";
            }

            if (!credentials.quantite) {
                errors.quantite = "Entrer la quantite";
            }

            if (!credentials.unite) {
                errors.unite = "Entrer l'unite";
            }

            if (!credentials.prix_unitaire) {
                errors.prix_unitaire = "Entrer le prix unitaire";
            }

            // if (!credentials.montant) {
            //     errors.montant = "Entrer le montant total";
            // }
           

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_ressource ? await update_ressources_budgets(credentials) : await create_ressources_budgets(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_ressource ? model_data : ressource_selected);
    }, [ressourcesBudgets])

    useEffect(() => {
        let options = []

        taches_data.map(item => {
            options.push({value: item.id, label: item.designation})
        })

        setOptionTache(options);
    }, [taches])


  return (
    <MainContent title={`${check_ressource ? 'Créer une ressource budget' : `Mettre à jour`.substring(0, 70) + '...'}`}>
        <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>

                <Select
                    label={"Tâche"}
                    required={true}
                    placeholder={"Selectionner la tâche"}
                    autoComplete='off'
                    value={credentials?.tache}
                    error={credentialsError.tache}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("tache", null)}
                    name={"tache"}
                    options={optionsTache}
                />

                <Input
                    type={"text"}
                    label={"Unité"}
                    required={true}
                    placeholder={"Unité"}
                    autoComplete='off'
                    value={credentials?.unite}
                    error={credentialsError.unite}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("unite", null)}
                    name={"unite"}
                />

                <Input
                    type={"number"}
                    label={"Quantité"}
                    required={true}
                    placeholder={"Quantité"}
                    autoComplete='off'
                    value={credentials?.quantite}
                    error={credentialsError.quantite}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("quantite", null)}
                    name={"quantite"}
                />

                <Input
                    type={"number"}
                    label={"Prix unitaire (FCFA)"}
                    required={true}
                    placeholder={"Prix unitaire (FCFA)"}
                    autoComplete='off'
                    value={credentials?.prix_unitaire}
                    error={credentialsError.prix_unitaire}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("prix_unitaire", null)}
                    name={"prix_unitaire"}
                />

                <Input
                    type={"number"}
                    label={"Montant (FCFA)"}
                    required={true}
                    placeholder={"Montant (FCFA)"}
                    autoComplete='off'
                    value={credentials?.prix_unitaire * credentials?.quantite}
                    error={credentialsError.montant}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("montant", null)}
                    name={"montant"}
                    disabled={true}
                />


                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>

        {!check_ressource && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_ressources_budgets(ressource_selected); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <AiOutlineDelete/>
                <span className='ml-1'>Supprimer</span>
                <span className='ml-1 text-dark'>(non recommandé)</span>
            </button>
        </div>}
    </div>
    </MainContent>
  )
}

export default ActiviteRessourceBudgetForm