import React from 'react'
import {usePlateformContext} from '../../contexts';
import { IMAGES } from '../../constances';

const Button = ({text, type, bgColor, textColor }) => {

  const {formLoading} = usePlateformContext();

  return (
    <button disabled={formLoading} type={type} className={`${bgColor + ' ' + textColor} mt-3 flex-grow text-light h-10 w-full flex justify-center items-center`}>
        {!formLoading ? text : <img width={30} height={30} src={IMAGES.loading_btn} alt='loading'/>}
    </button>
  )
}

export default Button