import React from 'react'
import { Button, Input, MainContent } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { getElementData } from '../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

const IndicateurForm = () => {

    const navigate = useNavigate();
    const {id_unite_gestion, id_indicateur} = useParams();
    const {indicateurs, unites_gestions, update_indicateur, create_indicateur, delete_indicateur} = usePlateformContext();
    let check_id_indicateur = id_indicateur === 'nouvelle';
    
    let indicateur = getElementData(id_indicateur, indicateurs);

    let model_data = {
        unite_gestion: id_unite_gestion,
        indicateur: "",
        cible: "",
        a_mi_parcours: "",
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.indicateur) {
                errors.indicateur = "Entrer l'indicateur";
            }

            if (!credentials.cible) {
                errors.cible = "Entrer la cible";
            }

            if (!credentials.a_mi_parcours) {
                errors.a_mi_parcours = "Entrer la mi-parcours";
            }
           

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_id_indicateur ? await update_indicateur(credentials) : await create_indicateur(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_id_indicateur ? model_data : indicateur);
    }, [unites_gestions])

  return (
    <MainContent title={`${check_id_indicateur ? 'Créer un indicateur' : indicateur?.indicateur}`}>
        <div className='flex flex-col justify-start items-center py-3'>
        <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

            <div>

                <Input
                    type={"text"}
                    label={"Indicateur"}
                    required={true}
                    placeholder={"Indicateur"}
                    autoComplete='off'
                    value={credentials?.indicateur}
                    error={credentialsError.indicateur}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("indicateur", null)}
                    name={"indicateur"}
                />

                <Input
                    type={"text"}
                    label={"Cible"}
                    required={true}
                    placeholder={"Cible"}
                    autoComplete='off'
                    value={credentials?.cible}
                    error={credentialsError.cible}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("cible", null)}
                    name={"cible"}
                />

                <Input
                    type={"text"}
                    label={"A mi-parcours"}
                    required={true}
                    placeholder={"A mi-parcours"}
                    autoComplete='off'
                    value={credentials?.a_mi_parcours}
                    error={credentialsError.a_mi_parcours}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("a_mi_parcours", null)}
                    name={"a_mi_parcours"}
                />

                <Button
                    type="submit"
                    text={"Enregistrer"}
                    bgColor={"bg-color-plt-1"}
                />

            </div>

        </form>

        {!check_id_indicateur && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_indicateur(indicateur); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <AiOutlineDelete/>
                <span className='ml-1'>Supprimer</span>
                <span className='ml-1 text-dark'>(non recommandé)</span>
            </button>
        </div>}
    </div>
    </MainContent>
  )
}

export default IndicateurForm