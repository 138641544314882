import React, { useEffect } from 'react'
import { LinkBtn, MainContent } from '../../../components'
import { AiFillCiCircle, AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai';
import { usePlateformContext } from '../../../contexts';
import { useParams } from 'react-router-dom';
import { FormatPrice } from '../../../constances';
import { FormatageNumero } from '../../../constances/functions';

const UtilisateursView = () => {

    const {id_unite_gestion} = useParams();
    const {utilisateurs} = usePlateformContext();

    let utilisateurs_data = utilisateurs.filter(item => item.unite_gestion?.toString() === id_unite_gestion) 

  return (
    <MainContent title={`Responsables projet`}>
        {/* Entete */}
        <div className='flex items-center mt-5 justify-end md:flex-row flex-col px-2 md:px-1 py-4'>
            <LinkBtn
                description={"Responsable projet"}
                icon={<AiOutlinePlusCircle/>}
                path={`/unites-gestions/${id_unite_gestion}/utilisateurs/nouvelle`}
                className={'bg-color-plt-1 text-light w-[100%] md:w-auto mb-2 md:mb-0'}
            />
        </div>

        {/* Tableau des composantes sous-composantes et volets d'activites */}
        <div className='container px-2 p-4'>
            {/* Composantes */}
            <div className='table-container mb-10'>
                <table className='table'>
                    <thead className='bg-dark text-light'>
                        <th>N°</th>
                        <th>Identifiant</th>
                        <th>Nom(s)</th>
                        <th>Prénom(s)</th>
                        <th>Genre</th>
                        <th>Téléphone</th>
                        <th>Email</th>
                        <th>Profile</th>
                        <th className='text-center'>Actif</th>
                    </thead>
                    <tbody>
                        {utilisateurs_data.map((item, index) => {
                            return(
                                <tr>
                                    <td className='w-[50px]'>{index + 1}</td>
                                    <td className='whitespace-normal'>{item.user_id}</td>
                                    <td className='whitespace-normal'>{item.nom}</td>
                                    <td className='whitespace-normal'>{item.prenom}</td>
                                    <td>{item.genre}</td>
                                    <td>{FormatageNumero(item.phone)}</td>
                                    <td>{item.email}</td>
                                    <td className='capitalize'>{item.profile}</td>
                                    <td>
                                        {item.active ? <span className='bg-color-plt-2 block w-[13px] h-[13px] rounded-[50%] mx-auto'></span>: <span className='bg-color-plt-6 block w-[13px] h-[13px] rounded-[50%] mx-auto'></span>}
                                    </td>

                                    <td className='w-[90px]'>
                                        <span className='flex'>
                                            <LinkBtn
                                                description={"Modifier"}
                                                icon={<AiOutlineEdit/>}
                                                className={"h-5"}
                                                path={`/unites-gestions/${id_unite_gestion}/utilisateurs/${item.id}`}
                                            />
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}

                        {!utilisateurs_data.length > 0 && <tr>
                                <td colSpan={9} className='h-20 text-center'>Aucun responsable projet</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </MainContent>
  )
}

export default UtilisateursView