import React from 'react'
import { Button, Input, MainContent } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { getElementData, obtenirPeriode } from '../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiFillCheckSquare, AiFillIeSquare, AiOutlineCheck, AiOutlineCheckSquare, AiOutlineDelete } from 'react-icons/ai';

const PtbaForm = () => {

    const navigate = useNavigate();
    const {id_unite_gestion, id_ptba} = useParams();
    const {unites_gestions, ptba, update_ptba, create_ptba, delete_ptba, sousActivites, composantes, sousComposantes, voletsActivites, categorieDepenses, activites} = usePlateformContext();
    
    let check_ptba = id_ptba === 'nouvelle';
    let ptba_selected = getElementData(id_ptba, ptba);

    let model_data = {
        unite_gestion: id_unite_gestion,
        annee: "",
        description: "",
        sous_activites: []
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.annee) {
                errors.annee = "Entrer l'année";
            }

            if (!credentials.description) {
                errors.description = "Entrer la description";
            }

            if (credentials.sous_activites.length === 0) {
                errors.sous_activites = "Selectionner au moins une sous activité";
            }
           

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = !check_ptba ? await update_ptba(credentials) : await create_ptba(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    useEffect(() => {
        setCredentials(check_ptba ? model_data : ptba_selected);
    }, [unites_gestions])

    let sous_activites_data = sousActivites.filter(item => item.unite_gestion.toString() === id_unite_gestion)
    let composantes_data = composantes.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let sousComposantes_data = sousComposantes.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let voletsActivites_data = voletsActivites.filter(item => item.unitegestion.toString() === id_unite_gestion)

    const checkSousActivite = (id) => {
        return credentials?.sous_activites?.some(item => item.id === id) ?? false;
    };

    const onClickSousActivite = (e) => {
        const listSousActivites = [...credentials?.sous_activites]; // Copie de la liste existante
      
        const existingItem = credentials?.sous_activites.find(item => item.id === e.id);
      
        if (existingItem) {
          const index = listSousActivites.indexOf(existingItem);
          listSousActivites.splice(index, 1); // Retire l'élément s'il existe déjà
        } else {
          listSousActivites.push(e); // Ajoute l'élément s'il n'existe pas
        }
      
        setCredentials({ ...credentials, sous_activites: listSousActivites });
      };

    console.log(credentials)


  return (
    <MainContent title={`${check_ptba ? 'Créer un PTBA' : `PTBA (${ptba_selected?.annee}) : ${ptba_selected?.description}`.substring(0, 70) + '...'}`}>
        <div className='flex flex-col justify-start items-center py-3'>
                <form onSubmit={handleSubmit} className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>

                    <div>
                        <Input
                            type={"text"}
                            label={"Année du PTBA"}
                            required={true}
                            placeholder={"Exemple: 2023-2024"}
                            autoComplete='off'
                            value={credentials?.annee}
                            error={credentialsError.annee}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("annee", null)}
                            name={"annee"}
                        />

                        <Input
                            type={"text"}
                            label={"Description"}
                            required={true}
                            placeholder={"Description"}
                            autoComplete='off'
                            value={credentials?.description}
                            error={credentialsError.description}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("description", null)}
                            name={"description"}
                        />

                    </div>

                    <div className='table-container my-5 bg-white'>

                        <div className='mb-2'>
                            <h2 className='font-medium m-0'>Sous-activites</h2>
                            <p className='font-light'>Sélectionner les activités pour le PTBA</p>
                        </div>

                        <div className="overflow-x-auto max-h-[60vh]">
                            <table className='table min-w-full'>
                                <thead className='bg-dark text-light sticky top-0'>
                                <tr>
                                    <th className="sticky left-0 bg-dark"></th>
                                    <th className="sticky left-0 bg-dark">Référence</th>
                                    <th className="sticky left-0 bg-dark">Designation</th>
                                    <th className="sticky left-0 bg-dark">Periode d'execution</th>
                                    <th className="sticky left-0 bg-dark">Montant</th>
                                    <th className="sticky left-0 bg-dark">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {sous_activites_data.map((item, index) => (
                                    <tr key={index}>
                                        <td onClick={() => onClickSousActivite(item)} className={`sticky left-0 text-lg bg-dark cursor-pointer ${checkSousActivite(item.id) ? 'text-green-700' : 'text-gray-500'}`} style={{backgroundColor: "#fff"}}>
                                            {checkSousActivite(item.id) ? <AiOutlineCheck/>: <span className='inline-flex border-[1px] border-gray-400 w-[15px] h-[15px]'></span>}
                                        </td>
                                        <td className=''>{item.reference}</td>
                                        <td className="whitespace-normal">{item.designation}</td>
                                        <td className="capitalize">{obtenirPeriode(item.date_debut, item.date_fin)}</td>
                                        <td className="whitespace-normal">{item.montant}</td>
                                        <td className="">{item.status}</td>
                                    </tr>
                                ))}
                                {!sous_activites_data.length > 0 && (
                                    <tr>
                                    <td colSpan={12} className='h-20'>Aucune sous-activité</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            </div>


                        {credentialsError.sous_activites && <div>
                            <span className='text-red-500 text-sm'>{credentialsError.sous_activites}</span>    
                        </div>}
                    </div>

                    <div>
                        <Button
                            type="submit"
                            text={"Enregistrer"}
                            bgColor={"bg-color-plt-1"}
                        />
                    </div>
                </form>

        {!check_ptba && <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
            <button onClick={() => {delete_ptba(ptba_selected); navigate(-1)}} className='text-color-plt-6 flex items-center text-[12px]'>
                <AiOutlineDelete/>
                <span className='ml-1'>Supprimer</span>
                <span className='ml-1 text-dark'>(non recommandé)</span>
            </button>
        </div>}
    </div>
    </MainContent>
  )
}

export default PtbaForm