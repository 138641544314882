import React from 'react'
import { Button, Input, MainContent, Select, Textarea } from '../../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../../contexts';
import { getElementData } from '../../../../constances';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiFillCheckSquare, AiOutlineDelete, AiOutlineUpSquare } from 'react-icons/ai';

const ActiviteInicateurForm = () => {

    const navigate = useNavigate();
    const {id_unite_gestion, id_activite, id_resultat} = useParams();
    const {resultatsAttendus, indicateurs, activites, update_activites} = usePlateformContext();

    let indicateurs_data = indicateurs.filter(item => item.unite_gestion.toString() === id_unite_gestion)
    let activite = getElementData(id_activite, activites)


    let model_data = {
        activite: id_activite,
        indicateurs: [],
    };

    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            // e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            // if (!credentials.indicateur) {
            //     errors.indicateur = "Selectionner un indicateur";
            // }

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = await update_activites(credentials);

                if (response) {
                    navigate(-1)
                }
            }
        
    }

    const [options_indicateurs, setOptionsIndicateurs] = useState([])

    useEffect(() => {
        setCredentials(activite);
    }, [activites, id_activite])


    useEffect(() => {
        let options = []

        indicateurs_data.map(item => {
            options.push({value: item.id, label: item.indicateur})
        })

        setOptionsIndicateurs(options)
    }, [indicateurs])


    const check_indicateur = (id) => {
        return credentials?.indicateurs?.includes(id)
    }

    const onClickIndicateur = (id) => {
        let list_indicateurs = credentials?.indicateurs

        const index = list_indicateurs.indexOf(id);

        if (index !== -1) {
            list_indicateurs.splice(index, 1);
        } else {
            list_indicateurs.push(id)
        }

        setCredentials({...credentials, indicateurs: list_indicateurs})
    }


  return (
    <MainContent title={`${'Ajouter un indicateur'}`}>
        <div className='flex flex-col justify-start items-center py-3'>

            <div className='w-[700px] max-w-[95%] bg-white p-3 rounded-md'>
                <div className='flex mb-5 justify-end'>
                    <button onClick={() => handleSubmit()} className={"bg-color-plt-1 w-[100px] text-light text-sm rounded py-1"}>Enregistrer</button>
                </div>

                <div className='table-container'>
                    <table className='table'>
                        <thead className='bg-dark text-light'>
                            <tr>
                                <th></th>
                                <th>Indicateurs</th>
                                <th>Cible</th>
                                <th>A Mi-parcours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {indicateurs_data.map((item, index) => {
                                return(
                                    <tr key={index}>
                                        <td className='w-[50px] text-center'>
                                            <button onClick={() => onClickIndicateur(item.id)} className='w-full flex justify-center'>
                                                {check_indicateur(item.id) ? <AiFillCheckSquare className='text-[20px] text-color-plt-2'/> : <span className='flex w-[15px] h-[15px] border-[1px] border-color-plt-2'></span>}
                                            </button>
                                        </td>
                                        <td className='whitespace-normal'>{item.indicateur}</td>
                                        <td className='center'>{item.cible}</td>
                                        <td className='center'>{item.a_mi_parcours}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </MainContent>
  )
}

export default ActiviteInicateurForm