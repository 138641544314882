import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AuthMain, Button, Input } from '../../components';
import { usePlateformContext } from '../../contexts';

const LoginView = () => {

  const {login, isAuthenticated} = usePlateformContext();
  const navigate = useNavigate();

  let model_data = {
    user_id: "",
    email: "",
    password: "",
  };

  const [credentials, setCredentials] = useState(model_data);
  const [credentialsError, setCredentialsError] = useState({});

  const handleChange = (e) => {
      setCredentials({...credentials, [e.target.name] : e.target.value})
  }

  const handleChangeError = (name, value) => {
      setCredentialsError({ ...credentialsError, [name]: value });
  }

  const handleSubmit = async (e) => {
      e.preventDefault()

      // Validation du formulaire

      const errors = {}; // Objet pour stocker les erreurs

      if (!credentials.user_id) {
          errors.user_id = "Entrer le numero d'identifiant";
      }

      // if (!credentials.email) {
      //     errors.email = "Entrer l'adresse e-mail";
      // }

      if (!credentials.password) {
          errors.password = "Entrer le mot de passe";
      }


      if (Object.keys(errors).length > 0) {
          setCredentialsError(errors);
      } else {
        let response = await login(credentials);

        if (response.sending_otp) {
          console.log("Envoie de l'otp ... redirection");
          navigate(`/otp`);
        }
      }
      
  }

  useEffect(() => {
      if(isAuthenticated){
          navigate('/')
      }
  }, [navigate, isAuthenticated])

  return (
    <AuthMain>
      <form onSubmit={handleSubmit} className='w-[400px] max-w-[95%] bg-white p-3 rounded-md'>

        <div className='mb-3'>
            <h3 className='text-xl mb-1 text-secondary'>Connexion</h3>
            <p className='text-dark text-sm font-normal'>Veuillez saisir vos identifiants de connexion pour accéder à votre compte <b>SSPP</b>.</p>
        </div>

        <div>
          <Input
            type={"text"}
            label={"Identifiant"}
            required={true}
            placeholder={"X9999"}
            autoComplete='off'
            value={credentials.user_id}
            error={credentialsError.user_id}
            onChange={handleChange}
            onFocus={() => handleChangeError("user_id", null)}
            name={"user_id"}
          />

          <Input
            type={"password"}
            label={"Mot de passe"}
            password={true}
            required={true}
            placeholder={"Mot de passe"}
            autoComplete='off'
            value={credentials.password}
            error={credentialsError.password}
            onChange={handleChange}
            onFocus={() => handleChangeError("password", null)}
            name={"password"}
          />

          <Button
            type="submit"
            text={"Connexion"}
            bgColor={"bg-color-plt-1"}
          />
        </div>

        <div className='mt-5'>
            <p className="text-normal text-dark text-sm">{"Mot de passe ou identifiant oublié ?"} <Link to={`/email-send-informations`} className="text-color-plt-1 font-medium cursor-pointer">Réinitialisez votre mot de passe.</Link></p>
        </div>
      </form>
    </AuthMain>
  )
}

export default LoginView