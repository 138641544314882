import React, { useEffect, useState } from 'react'
import { AcivitesTaches, ActivitesIndicateurs, ActivitesResltats, EvolutionTimeLine, LabelValue, LinkBtn, MainContent } from '../../../components'
import { Link, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { FormatDate, FormatPrice, IMAGES, check_admin, check_admin_composante, getElementData } from '../../../constances';
import { AiFillEdit, AiOutlineCheck, AiOutlinePlus, AiOutlinePlusCircle, AiOutlineWarning } from 'react-icons/ai';
import AcivitesRessourceBudget from '../../../components/AcivitesRessourceBudget';

const NotificationsView = () => {

  const {id_unite_gestion, id_tache} = useParams();
  const {taches, user, create_validation_tache, activites, sousActivites} = usePlateformContext();

  const hundleValidation = async (evolution, value) => {
    await create_validation_tache({
      evolution: evolution,
      user: user?.id,
      validated: value
    })
  }

  const [evolutions, setEvolutions] = useState([])

  useEffect(() => {
      let liste = []

      sousActivites.filter(item => item.unite_gestion.toString() === id_unite_gestion).map(sous_activite => {
          sous_activite.evolutions_sous_activite.map(evolution => {
              if (evolution.validated === null && check_admin_composante(user, evolution.composante)) {
                  liste.push(evolution)
              }
          })
      })

      setEvolutions(liste)
  }, [sousActivites])
  
  return (
    <MainContent title={`Notifications`}>
        <div className='container flex flex-col md:flex-row items-start justify-between flex-grow mt-5'>
          <div className='w-[100%] md:w-[60%] mx-auto'>

            {evolutions.length > 0 && <div className='bg-white p-2 rounded-md my-5'>
              <div className='flex items-center justify-start py-0'>
                <span className='font-bold text-sm text-color-plt-1'>Avancements des tâches</span>
              </div>

              {evolutions.map((item, index) => {

                let bgTimeLine = 'text-color-plt-2'

                if (item.pourcentage < 20) {
                  bgTimeLine = 'text-color-plt-6'
                } else if (item.pourcentage < 70) {
                  bgTimeLine = 'text-color-plt-4'
                }else {
                  bgTimeLine = 'text-color-plt-2'
                }

                return(
                  <div key={index} className={`border-[1px] mt-6 mb-2 p-1 rounded relative`}>

                      {(check_admin_composante(user, item?.composante) && item.validated === null) && <div className='flex justify-end absolute top-0 right-0'>
                        <LinkBtn
                            icon={<AiFillEdit/>}
                            className={"text-color-plt-1 text-lg"}
                            path={`/unites-gestions/${id_unite_gestion}/sous-activites/details/${item.sous_activite}/evolution/${item.id}`}
                        />
                      </div>}

                      <div>
                        <span className='font-medium text-sm'>Evolution (sur 100%) : </span>
                        <span className={`${bgTimeLine} font-bold`}>{item.pourcentage}% ({item.status})</span>
                      </div>

                      <div className='my-2'>
                        <span className='font-medium text-sm'>Commentaire : </span>

                        <div>
                          <p>{item.commentaire}</p>
                        </div>
                      </div>

                      {item.fichier && <div className='mb-3'>
                        <span className='font-medium text-sm'>Fichier joint : </span>
                        <div>
                          <a href={item.fichier} download>{item.fichier}</a>
                        </div>
                      </div>}

                      <div className='mb-3'>
                        <span className='font-medium text-sm'>Sous activité : </span>
                        <div>
                          <p>{getElementData(item.sous_activite, sousActivites)?.designation}</p>
                        </div>
                      </div>

                      <div className='mb-3'>
                        <Link 
                          to={`/unites-gestions/${id_unite_gestion}/sous-activites/details/${item.sous_activite}`}
                          className={`flex items-center text-color-plt-1`}>
                          <AiOutlinePlus className='text-sm'/>
                          <span className='ml-1 text-sm'>Plus de details</span>
                        </Link>
                      </div>

                      {(check_admin(user) && item.validated === null) && <div className='flex justify-between mt-3'>
                        <button onClick={() => hundleValidation(item.id, false)} className='w-[49%] h-10 text-color-plt-6 border-color-plt-6 rounded border-[1px] flex justify-center items-center'>
                          <AiOutlineCheck/>
                          <span className='ml-2 text-md'>Désapprouver</span>
                        </button>

                        <button onClick={() => hundleValidation(item.id, true)} className='w-[49%] h-10 text-color-plt-1 border-color-plt-1 rounded border-[1px] flex justify-center items-center'>
                          <AiOutlineCheck/>
                          <span className='ml-2 text-md'>Approuver</span>
                        </button>
                      </div>}

                      {(item.validated !== null) && <div className='flex justify-between mt-3'>
                        {item.validated ? <div className='w-full border-[1px] h-10 rounded flex items-center justify-center text-[gray]'>
                          <AiOutlineCheck/>
                          <span className='text-sm ml-2'>Approuvé</span>
                        </div> : 
                        
                        <div className='w-full border-[1px] h-10 rounded flex items-center justify-center text-[gray]'>
                          <AiOutlineCheck/>
                          <span className='text-sm ml-2'>Désapprouvé</span>  
                        </div>}
                      </div>}

                      {(user.profile !== "client-admin" && item.validated === null) && <div className='w-full border-[1px] h-10 rounded flex items-center justify-center text-[gray]'>
                          <AiOutlineCheck/>
                          <span className='text-sm ml-2'>En attente de validation</span>  
                        </div>}
                  </div>)
              })}
            </div>}

            {!evolutions.length > 0 && <div className='w-full min-h-[300px] border-[2px] flex flex-col justify-center items-center'>
                <img src={IMAGES.data_not_found} className='w-[100px] object-contain'/>
                <p>Aucune notifications</p>
            </div>}

          </div>
        </div>
    </MainContent>
  )
}

export default NotificationsView