import React, { useEffect, useState } from 'react'
import { AbonnementAlert, MainContent, OptionClick } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { check_admin, check_admin_composante, getElementData } from '../../../constances';
import { AiOutlineBarChart, AiOutlineBell, AiOutlineBlock, AiOutlineBuild, AiOutlineForward, AiOutlinePaperClip, AiOutlinePlusCircle, AiOutlineProfile, AiOutlineTag, AiOutlineUnorderedList, AiOutlineUser, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { toast } from 'react-toastify';

const UniteGestionView = () => {

    const {id_unite_gestion} = useParams();
    const navigate = useNavigate();
    const {unites_gestions, abonnement_last, user, taches, sousActivites} = usePlateformContext();

    let unite_gestion = getElementData(id_unite_gestion, unites_gestions);

    // Alerts notifications
    const [notifications, setNotifications] = useState(0);

    const options_1 = [
        {
            desabled: false,
            icon: <AiOutlineProfile/>,
            description: "PTBA",
            path: `/unites-gestions/${id_unite_gestion}/ptba`
        },

        {
            desabled: false,
            icon: <AiOutlineBarChart/>,
            description: "Reporting PTBA",
            path: `/unites-gestions/${id_unite_gestion}/reporting`
        },

        {
            desabled: false,
            icon: <AiOutlineBell/>,
            description: "Evolutions de taches",
            path: `/unites-gestions/${id_unite_gestion}/notifications`,
            notifications: notifications,
        },

        // {
        //     desabled: false,
        //     icon: <AiOutlinePaperClip/>,
        //     description: "Indicateurs",
        //     path: `/unites-gestions/${id_unite_gestion}/indicateurs`
        // },

        // {
        //     desabled: false,
        //     icon: <AiOutlineForward/>,
        //     description: "Plans d'actions",
        //     path: `/unites-gestions/${id_unite_gestion}/plans-actions`
        // },

        // {
        //     desabled: false,
        //     icon: <AiOutlinePlusCircle/>,
        //     description: "Nouvelle Activite",
        //     path: `/unites-gestions/${id_unite_gestion}/activites/nouvelle`
        // },
    ]

    const options_fonctionnalites = [
        {
            desabled: false,
            icon: <AiOutlineBuild/>,
            description: "Catégorie de dépenses",
            path: `/unites-gestions/${id_unite_gestion}/categorie-depenses`
        },
        
        {
            desabled: false,
            icon: <AiOutlineBlock/>,
            description: "Composantes",
            path: `/unites-gestions/${id_unite_gestion}/composantes`
        },

        {
            desabled: false,
            icon: <AiOutlineBlock/>,
            description: "Sous-composantes",
            path: `/unites-gestions/${id_unite_gestion}/sous-composantes`
        },

        {
            desabled: false,
            icon: <AiOutlineBlock/>,
            description: "Volets d'activités",
            path: `/unites-gestions/${id_unite_gestion}/volet-activite`
        },

        {
            desabled: false,
            icon: <AiOutlineBlock/>,
            description: "Activités",
            path: `/unites-gestions/${id_unite_gestion}/activites`
        },

        {
            desabled: false,
            icon: <AiOutlineBlock/>,
            description: "Sous-activtés",
            path: `/unites-gestions/${id_unite_gestion}/sous-activites`
        },

    ]

    const options_autres = [
        {
            desabled: false,
            icon: <AiOutlineUser/>,
            description: "Mon profile",
            path: `/profile`
        },

        {
            desabled: !check_admin(user),
            icon: <AiOutlineUsergroupAdd/>,
            description: "Responsable projet",
            path: `/unites-gestions/${id_unite_gestion}/utilisateurs`
        },

        {
            desabled: !check_admin(user),
            icon: <AiOutlineUnorderedList/>,
            description: "Abonnements",
            path: `/abonnements-liste`
        }
    ]


    useEffect(() => {
        if (Object.keys(abonnement_last).length > 0 && !abonnement_last.access) {
            toast.error("Abonnement innactif !");
            navigate("/");
        }
    }, [id_unite_gestion, abonnement_last])


    useEffect(() => {
        let counter = 0

        sousActivites.filter(item => item.unite_gestion.toString() === id_unite_gestion).map(tache => {
            tache.evolutions_sous_activite.map(evolution => {
                if (evolution.validated === null && check_admin_composante(user, evolution.composante)) {
                    counter = counter + 1
                }
            })
        })

        setNotifications(counter)
    }, [sousActivites])


  return (
    <MainContent title={`${unite_gestion?.designation}`}>
        {/* Entente Abonnement*/}
        <AbonnementAlert value={abonnement_last}/>
        
        {/* Contenue et options - fonctionnalites */}
        <div className='container px-2 p-4 flex flex-wrap items-center justify-between md:justify-start'>
            {options_fonctionnalites.map((item, index) => {
                if (!item.desabled) {
                    return(
                        <OptionClick 
                            key={index} 
                            icon={item.icon} 
                            description={item.description}
                            path={item.path} 
                        />
                    )
                }
            })}
        </div>

        <div className='container px-2 p-4 flex flex-wrap items-center justify-between md:justify-start'>
            {options_1.map((item, index) => {
                if (!item.desabled) {
                    return(
                        <OptionClick 
                            key={index} 
                            icon={item.icon} 
                            description={item.description}
                            path={item.path} 
                        />
                    )
                }
            })}
        </div>

        <div className='container px-2 pt-6 p-4 flex flex-wrap items-center justify-between md:justify-start border-t-[1px] border-gray-200'>
            {options_autres.map((item, index) => {
                if (!item.desabled) {
                    return(
                        <OptionClick 
                            key={index} 
                            icon={item.icon} 
                            description={item.description}
                            path={item.path} 
                            notifications={item.notifications}
                        />
                    )
                }
            })}
        </div>
    </MainContent>
  )
}

export default UniteGestionView